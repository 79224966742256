import React from 'react'
import {
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
} from '@material-ui/core'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import { months } from '../../../shared/state/constants/dateConstants'
import {
	NAME_PATTERN,
	NAME_MIN_LENGTH,
	DATE_MAX,
	NUMBER_PATTERN,
	YEAR_MAX,
	YEAR_MIN,
	EMAIL_PATTERN,
	DATE_MIN,
	YEAR_MAX_LENGTH,
	DATE_MAX_LENGTH,
	DOCUMENT_PATTERN
} from '../../../shared/state/constants/appConstants'
import './userGeneralInfoForm.scss'
import Alert from '../../../shared/components/atoms/alert/alert'

const UserGeneralInfoForm = props => {
	const {
		lblNombre,
		lblApellido,
		genero,
		fechaNacimiento,
		lblNumeroTelefonico,
	} = props.userFormInfo
	const isPasaporte = () => props.values.documentType === "Pasaporte";
	const CODE_PR_COUNTRY = 12
	const VALIDATION_PROPS_DOCUMENT_NUMBER = {
		required: { value: true, message: 'El documento es requerido.' },
		minLength: {
			value: 5,
			message: 'El documento debe tener mínimo 5 caracteres.',
		},
		maxLength: {
			value: 15,
			message: 'El documento debe tener máximo 15 caracteres.',
		},
		pattern: {
			value: isPasaporte() ? DOCUMENT_PATTERN : NUMBER_PATTERN,
			message: isPasaporte() ? "El documento no puede contener caracteres especiales." : "El documento no puede contener caracteres especiales ni letras.",
		}
	}
	const inputLabel = React.useRef(null)
	const cellPhoneLength = process.env.CELL_DIG

	return (
		<FormGroup className='f-user-form'>
			<InputMaterial
				label={lblNombre}
				errors={props.errors}
				name='name'
				type='text'
				classes='f-form-input'
				autoComplete={false}
				change={props.handleChange}
				register={props.register({
					required: { value: true, message: 'Tu nombre es requerido.' },
					pattern: {
						value: NAME_PATTERN,
						message: 'El nombre ingresado es inválido.',
					},
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'El nombre debe tener mínimo tres caracteres.',
					},
				})}
			/>
			<InputMaterial
				label={lblApellido}
				disabled={
					props.formValues.name === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState?.touched,
						'surname'
					)
				}
				errors={props.errors}
				name='surname'
				type='text'
				classes='f-form-input'
				autoComplete={false}
				change={props.handleChange}
				register={props.register({
					required: { value: true, message: 'Los apellidos son requeridos.' },
					pattern: {
						value: NAME_PATTERN,
						message: 'Los apellidos ingresados son inválidos.',
					},
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'Los apellidos debe tener mínimo tres caracteres.',
					},
				})}
			/>
			<FormControl
				disabled={
					props.formValues.surname === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState?.touched,
						'gender'
					)
				}
				variant='outlined'
				className='f-form-input f-select-input'
				error={props.errors.gender != null}
			>
				<InputLabel ref={inputLabel} htmlFor='outlined-gender-simple'>
					{genero}
				</InputLabel>
				<Select
					name='gender'
					value={props.values.gender}
					displayEmpty
					onChange={props.handleSelection()}
					input={<OutlinedInput name='gender' id='outlined-gender-simple' />}
				>
					<MenuItem value='Femenino'>Femenino</MenuItem>
					<MenuItem value='Masculino'>Masculino</MenuItem>
					<MenuItem value='Prefiero no responder'>
						Prefiero no responder
					</MenuItem>
				</Select>
				<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
			</FormControl>
			<FormControl
				disabled={
					props.formValues.gender === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState?.touched,
						'documentType'
					)
				}
				variant='outlined'
				className='f-form-input f-select-input f-form-input-date'
				error={props.errors.documentType != null}
			>
				<InputLabel htmlFor='outlined-document-type-simple'>
					{props.documentInfo.inputTipoDeDocumento}
				</InputLabel>
				<Select
					name='documentType'
					value={props.values.documentType}
					displayEmpty
					onChange={props.handleSelection()}
					input={
						<OutlinedInput
							name='documentType'
							id='outlined-document-type-simple'
						/>
					}
				>
					{props.documentInfo.tipoDeDocumento.map((option, index) => (
						<MenuItem value={option.value} key={index}>
							{option.name}
						</MenuItem>
					))}
				</Select>
				<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				{props.errors.documentType != null && (
					<Alert
						alertType='error f-alert-commerce'
						description='Debes seleccionar una opción'
					/>
				)}
			</FormControl>
			<InputMaterial
				label={props.documentInfo.inputNumeroDeDocumento}
				disabled={
					props.formValues.documentType === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState?.touched,
						'document'
					)
				}
				blurEvent={e => props.checkDocumentRegister(e.target.value)}
				messageError={
					props.isDocumentRegistered ? props.isDocumentRegistered : null
				}
				errors={props.errors}
				name='document'
				type='text'
				classes='f-form-input f-form-input-date'
				autoComplete={false}
				change={e => {
					props.handleChange(e)
					props.setDocumentRegistered(false)
				}}
				register={props.register(
					Number(props.values.country) === CODE_PR_COUNTRY
						? { ...VALIDATION_PROPS_DOCUMENT_NUMBER, required: false }
						: VALIDATION_PROPS_DOCUMENT_NUMBER
				)}
			/>
			<div className='f-date-container'>
				<label className='f-date-label'>{fechaNacimiento}</label>
				<InputMaterial
					change={e => {
						if (e.target.value !== null && e.target.value.length > 0) {
							const newValue = e.target.value.replace(/\D/g, '')
							props.setValue('date', newValue)
						}
						props.handleChange(e)
					}}
					blurEvent={props.getNewDate()}
					disabled={
						props.formValues.document === '' &&
						!Object.prototype.hasOwnProperty.call(
							props.formState?.touched,
							'date'
						) &&
						!props.isMyAccount
					}
					errors={[]}
					name='date'
					type='number'
					autoComplete={false}
					classes='f-form-input f-date'
					id='outlined-date'
					label='Día'
					inputRef={props.register({
						required: true,
						min: DATE_MIN,
						max: DATE_MAX,
						maxLength: DATE_MAX_LENGTH,
						pattern: NUMBER_PATTERN,
					})}
					register={props.register({
						required: { value: true, message: 'Este campo es requerido.' },
						pattern: {
							value: NUMBER_PATTERN,
							message: 'Este campo solo acepta números.',
						},
						min: { value: DATE_MIN, message: 'Ingresa mínimo 1 cifra.' },
						max: { value: DATE_MAX, message: 'Ingresa máximo 2 cifra.' },
						maxLength: {
							value: DATE_MAX_LENGTH,
							message: 'Ingresa máximo 2 cifras.',
						},
					})}
				/>
				<FormControl
					variant='outlined'
					className='f-form-input f-select-input f-month'
					disabled={
						props.formValues.date === '' &&
						!Object.prototype.hasOwnProperty.call(
							props.formState?.touched,
							'month'
						) &&
						!props.isMyAccount
					}
				>
					<Select
						error={props.errors.month != null}
						value={props.values.month}
						displayEmpty
						name='month'
						onChange={props.handleSelection()}
						onBlur={props.getNewDate()}
						input={<OutlinedInput id='outlined-age-simple' />}
					>
						<MenuItem value=''>
							<em>{'Mes'}</em>
						</MenuItem>
						{months.map(month => (
							<MenuItem key={'month' + month.number} value={month.number}>
								{month.month}
							</MenuItem>
						))}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>
				<InputMaterial
					change={props.handleChange}
					blurEvent={props.getNewDate()}
					disabled={
						props.values.month === '' &&
						!Object.prototype.hasOwnProperty.call(
							props.formState?.touched,
							'year'
						) &&
						!props.isMyAccount
					}
					errors={[]}
					name='year'
					type='number'
					label='Año'
					autoComplete={false}
					classes='f-form-input  f-year'
					id='outlined-year'
					placeholder={'Año'}
					register={props.register({
						required: { value: true, message: 'Este campo es requerido.' },
						pattern: {
							value: NUMBER_PATTERN,
							message: 'Este campo solo acepta números.',
						},
						min: { value: YEAR_MIN, message: 'El año es inválido.' },
						max: { value: YEAR_MAX, message: 'El año es inválido.' },
						maxLength: {
							value: YEAR_MAX_LENGTH,
							message: 'El año está incorrecto.',
						},
					})}
				/>
				{(props.errors.date || props.errors.year) && (
					<div className='f-alert-date'>
						<Alert
							alertType='error'
							description={
								props.errors.date
									? props.errors.date.message
									: props.errors.year.message
							}
						/>
					</div>
				)}
			</div>
			<InputMaterial
				change={e => {
					if (e.target.value !== null && e.target.value.length > 0) {
						const newValue = e.target.value.replace(/\D/g, '')
						props.setValue('cellPhone', newValue)
					}
					props.setPhoneRegistered(false)
					props.handleChange(e)
				}}
				blurEvent={props.checkPhoneRegister}
				disabled={
					props.formValues.cellPhone === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState?.touched,
						'cellPhone'
					)
				}
				messageError={
					props.isPhoneRegistered
						? 'Este número celular ya se encuentra registrado.'
						: null
				}
				name='cellPhone'
				type={'text'}
				errors={props.errors}
				autoComplete={false}
				classes={'f-form-input'}
				id={'outlined-cellphone'}
				label={lblNumeroTelefonico}
				register={props.register({
					required: { value: true, message: 'Tu número celular es requerido.' },
					pattern: { value: NUMBER_PATTERN, message: 'Ingresa sólo números.' },
					minLength: {
						value: cellPhoneLength,
						message: `Un número celular debe tener ${cellPhoneLength} dígitos.`,
					},
					maxLength: {
						value: cellPhoneLength,
						message: `Un número celular debe tener ${cellPhoneLength} dígitos.`,
					},
				})}
			/>

			<InputMaterial
				errors={props.errors}
				name='email'
				type='text'
				autoComplete={false}
				classes='f-form-input f-form-input-email'
				id={'f-institution-helper-email'}
				label={'¿Cuál es tu correo electrónico?'}
				onClick={() => {
					props.changeModalEmail()
				}}
				disabled={props.isAdviser}
				register={props.register({
					required: { value: true, message: 'El correo es requerido.' },
					pattern: {
						value: EMAIL_PATTERN,
						message: 'El correo ingresado es inválido.',
					},
					minLength: {
						value: 8,
						message: 'El email debe tener mínimo 8 caracteres.',
					},
				})}
			/>
			<div className='f-form-input'></div>
		</FormGroup>
	)
}

export default UserGeneralInfoForm
