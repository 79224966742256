import {
	FormControl,
	FormGroup,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@material-ui/core'
import SelectMaterial from '../../../shared/components/atoms/select-material/select-material'
import React, { useState } from 'react'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import './userLocationInfoForm.scss'
import Radio from '../../../shared/components/atoms/radio/radio'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import { NOSCRIPT_PATTERN, NO_SPECIAL_CHARACTERS_PATTERN, ADDRESS_PATTERN } from '../../../shared/state/constants/appConstants'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const copiesForm = {
	kindOfStreet: 'Tipo de calle',
	street: 'Calle',
	streetNumber: 'Número',
	streetSecondaryNumber: '',
	neighborhood: 'Barrio',
	address: 'Bloque o torre / Apartamento',
	aditionalReferences: 'Referencias adicionales de la dirección',
}

const UserLocationInfoForm = props => {
	const { direccion, pais, ciudad, departamento } = props.userFormInfo
	const currentCountry = props.locationInfo[props.countryPosition].codigoDelPais
	const isSecondForm =
		props.secondLocationForm && props.location === 'secondaryLocation'
	const inputLabel = React.useRef(null)
	const userInfo = dataLayerUserInfo()
	const [kindOfStreet, setKindOfStreet] = useState(props.values.kindOfStreet? props.values.kindOfStreet.name : '')
	const [secondKindOfStreet, setSecondKindOfStreet] = useState(props.values.secondKindOfStreet? props.values.secondKindOfStreet.name : '' )
	const isCO = process.env.LOCALE === 'es-CO'
	const haveSecondAddress = props.values.secondAddress !== ' '

	
	const dataLaterDatosP = (lab) => {
		let loc = ''
		if (typeof window !== 'undefined') {
			loc = window.location.href
		}
		pushDataLayerEvent({
			event: 'Interacciones - Beneficios',
			category: 'beneficios',
			action: 'click',
			label: lab,
			location: loc,
			user_data: userInfo.user_data ? userInfo.user_data : '',
			log_status: userInfo.log_status,
		})
	}
	const valueAncientAddress = () =>{
		const fullAddress = !isSecondForm ? `${props.values.address} ${props.values.mainAddressDetail}`.toString() : `${props.values.secondAddress} ${props.values.secondAddressDetail}`
		return fullAddress
	}
	
	return (
		<>
			{isCO && !props.values.isNewAddress ? (
				<>
				<InputMaterial
					change={props.handleChange}
					disabled={true}
					errors={props.errors}
					type='text'
					autoComplete={false}
					classes='f-form-input f-form-input-ancientAddress'
					id={'f-user-neighborhood'}
					label={'Dirección Antigua'}
					value={ valueAncientAddress().replaceAll('?',' ')}
				/>
				<p className='f-section-subtitle'>
							Por favor actualiza tu dirección
				</p>
				</>
			) : isCO && isSecondForm && !props.values.isSecondNewAddress && !haveSecondAddress && (
				<>
				<InputMaterial
					change={props.handleChange}
					disabled={true}
					errors={props.errors}
					type='text'
					autoComplete={false}
					classes='f-form-input f-form-input-ancientAddress'
					id={'f-user-neighborhood'}
					label={'Dirección Antigua'}
					value={ valueAncientAddress().replaceAll('?',' ')}
				/>
				<p className='f-section-subtitle'>
							Por favor actualiza tu dirección
				</p>
				</>
			)
		}
			<FormGroup
				className={`${
					currentCountry === '12'
						? 'f-user-form f-user-form--two-inputs'
						: 'f-user-form'
					}`}
			>
				<FormControl
					variant='outlined'
					className='f-select-input f-select-input-sample'
					disabled={true}
				>
					<InputLabel
						filled={true}
						ref={inputLabel}
						htmlFor='outlined-country-simple'
					>
						{pais}
					</InputLabel>
					<Select
						value={props.values.country || ''}
						onChange={props.handleSelection()}
						input={
							<OutlinedInput
								name={isSecondForm ? 'secondCountry' : 'country'}
								id={
									isSecondForm
										? 'outlined-country-simple-sec'
										: 'outlined-country-simple'
								}
							/>
						}
					>
						{props.locationInfo.map((item, index) => (
							<MenuItem key={index} value={item.codigoDelPais}>
								{item.titulo}
							</MenuItem>
						))}
					</Select>
					{/* <Icon icon="icon-chevron-down lonchmara" size="0-7" tagtype="i" /> */}
				</FormControl>
				<FormControl
					variant='outlined'
					className='f-select-input f-select-input-sample'
					disabled={
						props.formValues.country === '' &&
						!Object.prototype.hasOwnProperty.call(
							props.formState?.touched,
							'state'
						) &&
						!props.isMyAccount
					}
				>
					<InputLabel ref={inputLabel} htmlFor='outlined-state-simple'>
						{departamento}
					</InputLabel>
					<Select
						value={
							isSecondForm
								? props.values.secondState || ''
								: props.values.state || ''
						}
						displayEmpty
						onChange={props.handleSelection()}
						input={
							<OutlinedInput
								name={isSecondForm ? 'secondState' : 'state'}
								id={
									isSecondForm
										? 'outlined-state-simple-sec'
										: 'outlined-state-simple'
								}
							/>
						}
					>
						{props.locationInfo[props.countryPosition].departamentos.map(
							state => (
								<MenuItem key={'state' + state.codigo} value={state}>
									{state.nombre}
								</MenuItem>
							)
						)}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>
				{currentCountry === '12' ? null : (
					<FormControl
						variant='outlined'
						className='f-select-input f-select-input-sample'
						disabled={
							props.formValues.state === '' &&
							!Object.prototype.hasOwnProperty.call(
								props.formState?.touched,
								'city'
							) &&
							!props.isMyAccount
						}
					>
						<InputLabel ref={inputLabel} htmlFor='outlined-city-simple'>
							{ciudad}
						</InputLabel>
						<Select
							name={isSecondForm ? 'secondCity' : 'city'}
							value={
								isSecondForm ? props.values.secondCity : props.values.city || ''
							}
							displayEmpty
							onChange={props.handleSelection()}
							input={
								<OutlinedInput
									name={isSecondForm ? 'secondCity' : 'city'}
									id={
										isSecondForm
											? 'outlined-city-simple-sec'
											: 'outlined-city-simple'
									}
								/>
							}
						>
							{(props.citiesArray || []).map(city => (
								<MenuItem key={'city' + city.codigo} value={city}>
									{city.nombre}
								</MenuItem>
							))}
						</Select>
						<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
					</FormControl>
				)}
				{process.env.LOCALE !== 'es-CO' ? null :
					<>
						<div className='f-form-input-group'>
							<SelectMaterial
								styles='f-form-input f-form-input--institute f-select-input-sample--street'
								name={isSecondForm ? 'secondKindOfStreet' : 'kindOfStreet'}
								isStreet={true}
								label="Tipo de calle *"
								errors={props.errors}
								change={(event) => {
									props.handleChange(event)
									isSecondForm ? props.setValue('secondKindOfStreet',event.target.value) : props.setValue('kindOfStreet',event.target.value)
									!isSecondForm ? setKindOfStreet(event.target.value) : setSecondKindOfStreet(event.target.value)
								}}
								setValue={value => {!isSecondForm ? setKindOfStreet(value) : setSecondKindOfStreet(value)  }}
								rules={{
									required: {
										value: true,
										message: "Este campo es requerido.",
									},
								}}
								control={props.control}
								items={props.locationInfo[props.countryPosition].tipoDeCalle.map(({ idCalle, name }) => ({
									key: idCalle,
									value: name,
								}))}
							/>
							<InputMaterial
								errors={props.errors}
								name={isSecondForm ? 'secondStreet' : 'street'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-medium'
								id='f-user-street'
								label={isSecondForm ? secondKindOfStreet : kindOfStreet}
								change={(event) =>{
										props.handleChange(event)
								}}
								register={props.register({
									required: {
										value: true,
										message: 'Campo requerido.',
									},
									maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos.',
									}
								})}
							/>
							<InputMaterial
								isNumberAddress = {true}
								characterAddress='# '
								change={e => {
									props.handleChange(e)
								}}
								errors={props.errors}
								name={isSecondForm ? 'secondStreetNumber' : 'streetNumber'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-medium'
								id='f-user-number'
								label={copiesForm.streetNumber}
								register={props.register({
									required: { value: isSecondForm ? secondKindOfStreet !== 'Manzana' : kindOfStreet !== 'Manzana', message: "El campo es requerido." },
									maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos.',
									}
								  })}
							/>
							<InputMaterial
								isNumberAddress = {true}
								characterAddress='- '
								change={(event) => {
									props.handleChange(event)
								}}
								errors={props.errors}
								name={isSecondForm? 'secondStreetSecondaryNumber' : 'streetSecondaryNumber'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-medium'
								id='f-user-secondNumber'
								label={copiesForm.streetSecondaryNumber}
								register={props.register({
									required: { value: isSecondForm ? secondKindOfStreet !== 'Manzana' : kindOfStreet !== 'Manzana', message: "El campo es requerido." },
									maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos.',
									}
							})}
							/>
						</div>
						<InputMaterial
							change={(e)=>{
								props.handleChange(e)
							}}
							errors={props.errors}
							name={isSecondForm ? 'secondNeighborhood' : 'neighborhood'}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-neighborhood'
							id='f-user-secondNumber'
							label={copiesForm.neighborhood}
							register={props.register({
								required: {
									value: true,
									message: 'Campo obligatorio.',
								},
								maxLength: {
									value: 50,
									message: 'El campo debe tener máximo 50 caracteres',
								},
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'El campo contiene caracteres inválidos.',
								}
							})}
						/>
					</>
				}
				{isSecondForm && !isCO ?  (
					<>
						<InputMaterial
							change={props.handleChange}
							disabled={
								currentCountry[0].codigoDelPais !== '12'
									? props.formValues.city === '' &&
								  !Object.prototype.hasOwnProperty.call(
										props.formState?.touched,
										'address'
									) &&
									!props.isMyAccount
									: props.formValues.state === '' &&
									!Object.prototype.hasOwnProperty.call(
										props.formState?.touched,
										'state'
									) &&
									!props.isMyAccount
							}
							errors={props.errors}
							name='secondAddress'
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-address'
							id={'f-user-address-sec'}
							label={direccion}
							register={props.register({
								required: {
									value: true,
									message: 'La dirección es requerida.',
								},
								minLength: {
									value: 15,
									message: 'La dirección debe tener mínimo 15 caracteres.',
								},
								maxLength: {
									value: 300,
									message: 'La dirección debe tener máximo 300 caracteres.',
								},
								pattern: {
									value: ADDRESS_PATTERN,
									message: 'La dirección es inválida.',
								},
							})}
						/>
						<InputMaterial
							change={props.handleChange}
							errors={props.errors}
							name='secondAddressDetail'
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-neighborhood'
							id={'f-user-neighborhood-sec'}
							label={'Barrio / edificio / apartamento'}
							register={props.register({
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'La dirección es inválida.',
								},
								maxLength: {
									value: 100,
									message: 'Este campo debe tener máximo 100 caracteres.',
								},
							})}
						/>
					</>
				) : isSecondForm && isCO ?  (
					<>
						<InputMaterial
							change={(e)=>{
								props.handleChange(e)
							}}
							errors={props.errors}
							name={'secondBlock'}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-address'
							id='f-user-block'
							label={'Bloque o torre / Apartamento'}
							register={props.register({
								required: {
									value: true,
									message: 'Campo requerido.',
								},
								maxLength: {
									value: 50,
									message: 'El campo debe tener máximo 50 caracteres',
								},
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'El campo contiene caracteres inválidos.',
								}
							})}
						/>
						<InputMaterial
							change={(e) => {
								props.handleChange(e)
							}}
							errors={props.errors}
							name={'secondAditionalReferences'}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-neighborhood'
							id={'aditionalInformation'}
							label={'Referencias adicionales' }
							register={props.register({
								maxLength: {
									value: 50,
									message: 'El campo debe tener máximo 50 caracteres',
								},
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'El campo contiene caracteres inválidos.',
								}
							})}
						/>
					</>
				) : !isSecondForm && !isCO ? (
					<>
						<InputMaterial
							change={props.handleChange}
							disabled={
								currentCountry[0].codigoDelPais !== '12'
									? props.formValues.city === '' &&
									!Object.prototype.hasOwnProperty.call(
										props.formState?.touched,
										'address'
									) &&
									!props.isMyAccount
									: props.formValues.state === '' &&
									!Object.prototype.hasOwnProperty.call(
										props.formState?.touched,
										'state'
									  ) &&
									  !props.isMyAccount
							}
							errors={props.errors}
							name='address'
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-address'
							id='f-user-address'
							label={direccion}
							register={props.register({
								required: {
									value: true,
									message: 'La dirección es requerida.',
								},
								minLength: {
									value: 15,
									message: 'La dirección debe tener mínimo 15 caracteres.',
								},
								pattern: {
									value: NOSCRIPT_PATTERN,
									message: 'La dirección es inválida.',
								},
							})}
						/>
						<InputMaterial
							change={props.handleChange}
							errors={props.errors}
							name='mainAddressDetail'
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-neighborhood'
							id={'f-user-neighborhood'}
							label={'Barrio / edificio / apartamento'}
							register={props.register({
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'La dirección es inválida.',
								},
								maxLength: {
									value: 100,
									message: 'Este campo debe tener máximo 100 caracteres.',
								},
							})}
						/>
					</>
				): (
					<>
						<InputMaterial
							change={(e)=>{
								props.handleChange(e)
							}}
							errors={props.errors}
							name={'block'}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-address'
							id='f-user-block'
							label={'Bloque o torre / Apartamento'}
							register={props.register({
								required: {
									value: true,
									message: 'Campo requerido.',
								},
								maxLength: {
									value: 50,
									message: 'El campo debe tener máximo 50 caracteres',
								},
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'El campo contiene caracteres inválidos.',
								}
							})}
						/>
						<InputMaterial
							change={(e)=>{
								props.handleChange(e)
							}}
							errors={props.errors}
							name={'aditionalReferences'}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-neighborhood'
							id={'aditionalInformation'}
							label={'Referencias adicionales' }
							register={props.register({
								maxLength: {
									value: 50,
									message: 'El campo debe tener máximo 50 caracteres',
								},
								pattern: {
									value: NO_SPECIAL_CHARACTERS_PATTERN,
									message: 'El campo contiene caracteres inválidos.',
								}
							})}
						/>
					</>
				)
			}
				{props.isMyAccount &&
					(props.secondLocationForm ? (
						<div className='f-btn-add-wrapper'>
							<div className={'f-btn-radio'} onClick={props.addLocationForm}>
								<Radio
									isActive={props.location === props.selectedLocation}
									setActive={() => {
										props.location === 'secondaryLocation'
											? props.selectLocation('secondaryLocation')
											: props.selectLocation('mainLocation')
									}}
								/>
							</div>
							<p>Establecer como dirección de envio</p>
						</div>
					) : (
						<>
							<div className='f-btn-add-wrapper'>
								<div className={'f-btn-add'} onClick={() => { props.addLocationForm(); dataLaterDatosP('Agregar otra dirección') }}>
									<p>{convertText('AGREGAR OTRA DIRECCIÓN')}</p>
								</div>
							</div>
						</>
					))}
			</FormGroup>
		</>
	)
}

export default UserLocationInfoForm
