import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../shared/components/atoms/Button/Button'
import Radio from '../../../shared/components/atoms/radio/radio'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import RegisterService from '../../../shared/services/register'
import SessionService from '../../../shared/services/session'
import * as constants from '../../../shared/state/constants/appConstants'
import ButtonLoading from '../../molecules/buttonLoading/buttonLoading'
import UserGeneralInfoForm from '../../molecules/userGeneralInfoForm/userGeneralInfoForm'
import UserLocationInfoForm from '../../molecules/userLocationInfoForm/userLocationInfoForm'
import UserLocationInfoFormARG from '../../molecules/userLocationInfoFormARG/userLocationInfoFormARG'
import UserRolesFormInfo from '../../molecules/userRolesFormInfo/userRolesFormInfo'
import ChangePassContainer from '../changePassContainer/changePassContainer'
import ChangeEmailContainer from '../change-email-container/change-email-container'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import AdviserService from '../../../shared/services/adviser'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import Alert from '../../../shared/components/atoms/alert/alert'
import useDocument from '../../../shared/hooks/useDocument'
import ProfileService from '../../../shared/services/profile'
import './userAccountContainer.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const UserAccountContainer = props => {
	const { passwordModal, setPasswordModal } = props
	const { locale } = useLocaleMode()
	const [isVisbleModalEmail, setIsVisbleModalEmail] = React.useState(false)
	const [secondLocationForm, setSecondLocationForm] = React.useState(false)
	const [citiesArray, setCities] = React.useState([])
	const [citiesSecondArray, setSecondCities] = React.useState([])
	const [selectedLocation, setLocation] = React.useState('mainLocation')
	const [isLoaderVisible, setLoaderVisible] = React.useState(false)
	const [userInfo] = React.useState(props.storageInfo && props.storageInfo)
	const [notiTena, setNotiTena] = React.useState(userInfo.shippingMainAddres)
	const [previouslyPurchased, setPreviouslyPurchased] = React.useState(
		userInfo.isCustomer === null ? null : userInfo.isCustomer
	)
	const isCO = process.env.LOCALE === 'es-CO'
	const isAR = process.env.LOCALE === 'es-AR'
	const [countryPosition, setCountryPosition] = React.useState(
		userInfo.mainCountry !== null && userInfo.mainCountry !== undefined
			? props.locationInfo.findIndex(
					node => Number(node.codigoDelPais) === Number(userInfo.mainCountry)
			  )
			: props.locationInfo.findIndex(node =>
					locale.includes(node.codigoDeDosLetras)
			  )
	)
	const findKindOfStreet = (nameStreet) => {
		return props.locationInfo[countryPosition].tipoDeCalle.find(ele => ele.name === nameStreet)
	}

	// document variables
	const documentInfo = useDocument()
	const [isNotiTenaVisible] = React.useState(() => {
		switch (locale) {
			case 'es-CO':
			case 'es-PE':
			case 'es-PR':
			case 'es-DO':
				return true
			default:
				return false
		}
	})
	const [contactMethod, setContactMethod] = React.useState(() => {
		const newArray = []
		if (userInfo.smsContact) {
			newArray.push('SMS')
		}
		if (userInfo.emailContact) {
			newArray.push('EMAIL')
		}
		if (userInfo.whatsappContact) {
			newArray.push('WPP')
		}
		return newArray
	})
	const [roles, setRoles] = React.useState(userInfo.roles || [])

	const prefijo = props.locationInfo[countryPosition].prefijoTelefonico
	if (userInfo.cellPhone) {
		userInfo.cellPhone = parseInt(
			userInfo.cellPhone.toString().replace(prefijo, '')
		)
	}
	let newDate = {}
	if (userInfo && userInfo.birthdate) {
		const realDate = new Date(userInfo.birthdate)
		newDate = {
			year: realDate.getUTCFullYear(),
			month: realDate.getUTCMonth() + 1,
			date: realDate.getUTCDate(),
		}
	}

	const CODE_PR_COUNTRY = 12
	const isFirefox = typeof InstallTrigger !== 'undefined'
	const userInitialInfo = props.storageInfo
	userInitialInfo.surname = props.storageInfo.lastName
	userInitialInfo.cellPhone = userInfo.cellPhone
	userInitialInfo.year = newDate.year
	userInitialInfo.month = newDate.month
	userInitialInfo.date = newDate.date
	const {
		register,
		control,
		handleSubmit,
		errors,
		setValue,
		formState,
		triggerValidation,
		getValues,
		setError,
		clearError,
	} = useForm({
		criteriaMode: 'firstError',
		defaultValues: {
			name: userInfo.name || '',
			surname: userInfo.lastName || '',
			gender: userInfo.genero || '',
			documentType: userInfo.typeId || '',
			document: userInfo.numberId || '',
			email: userInfo.email || '',
			date: newDate.date || '',
			month: newDate.month || '',
			year: newDate.year || '',
			cellPhone: userInfo.cellPhone || '',
			freeSample: userInfo.freeSample || '',
			country:
				userInfo.mainCountry ||
				props.locationInfo[countryPosition].codigoDelPais,
			state:
				Number(props.locationInfo[countryPosition].codigoDelPais) !==
				CODE_PR_COUNTRY
					? userInfo.mainCountryState || ''
					: userInfo.mainCity || '',
			city:
				Number(props.locationInfo[countryPosition].codigoDelPais) !==
				CODE_PR_COUNTRY
					? userInfo.mainCity || ''
					: '',
			address: userInfo.mainAddress || '',
			kindOfStreet: userInfo.address ?  userInfo.address.tipoCalle : '',
			street: userInfo.address ?  userInfo.address.numeroTipo : '',
			streetNumber: userInfo.address ?  userInfo.address.numero.slice(2) : '',
			streetSecondaryNumber: userInfo.address ?  userInfo.address.numeroSecundario.slice(2) : '',
			neighborhood: userInfo.addressDetail ? userInfo.addressDetail.barrio : '',
			block: userInfo.addressDetail ? userInfo.addressDetail.detalle : '',
			aditionalReferences: userInfo.addressDetail ? userInfo.addressDetail.referencia : '',
			secondKindOfStreet: userInfo.secondNewAddress ?  userInfo.secondNewAddress.tipoCalle : '',
			secondStreet: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroTipo : '',
			secondStreetNumber: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numero.slice(2) : '',
			secondStreetSecondaryNumber: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroSecundario.slice(2) : '',
			secondNeighborhood: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.barrio : '',
			secondBlock: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.detalle : '',
			secondAditionalReferences: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.referencia : '',
			secondAddress: userInfo.secondAddress || '',
			contactMethod: {
				emailContact: userInfo.emailContact,
				SMSContact: userInfo.smsContact,
			},
			mainAddressDetail: userInfo.mainAddressDetail || '',
			secondAddressDetail: userInfo.secondAddressDetail || '',

			/* Campos Argentina */
			addressARG: userInfo.address ?  userInfo.address.tipoCalle : '',
			height: userInfo.address ?  userInfo.address.numeroTipo : '',
			floor: userInfo.address ?  userInfo.address.numero === "null" ? 'Sin piso' : userInfo.address.numero : '',
			department: userInfo.address ?  userInfo.address.numeroSecundario === "null" ? 'Sin departamento' : userInfo.address.numeroSecundario : '',
			zipCode: userInfo.addressDetail ? userInfo.addressDetail.barrio : '',
			aditionalInformation: userInfo.addressDetail ? userInfo.addressDetail.detalle === "null" ? '' : userInfo.addressDetail.detalle : '',
			secondAddressARG: userInfo.secondNewAddress ?  userInfo.secondNewAddress.tipoCalle : '',
			secondHeight: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroTipo : '',
			secondFloor: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numero === "null" ? 'Sin piso' : userInfo.secondNewAddress.numero : '',
			secondDepartment: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroSecundario === "null" ? 'Sin departamento' : userInfo.secondNewAddress.numeroSecundario : '',
			secondZipCode: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.barrio : '',
			secondAditionalInformation: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.detalle === "null" ? '' : userInfo.secondNewAddressDetail.detalle : '',
		},
	})
	const formValues = getValues()
	const [values, setValues] = React.useState({
		name: userInfo.name || '',
		surname: userInfo.lastName || '',
		gender: userInfo.gender || '',
		documentType: userInfo.typeId || '',
		document: userInfo.numberId || '',
		month: newDate.month || '',
		fullDate: '',
		country:
			(userInfo.mainCountry && userInfo.mainCountry) ||
			props.locationInfo[countryPosition].codigoDelPais,
		state: userInfo.mainCountryState || '',
		city: userInfo.mainCity || '',
		kindOfStreet: !isAR && userInfo.address ?  findKindOfStreet(userInfo.address.tipoCalle) : '',
		street: userInfo.address ?  userInfo.address.numeroTipo : '',
		streetNumber: userInfo.address ?  userInfo.address.numero.slice(2) : '',
		streetSecondaryNumber: userInfo.address ?  userInfo.address.numeroSecundario.slice(2) : '',
		neighborhood: userInfo.addressDetail ? userInfo.addressDetail.barrio : '',
		address: userInfo.mainAddress || '',
		mainAddressDetail: userInfo.mainAddressDetail || '',
		block: userInfo.addressDetail ? userInfo.addressDetail.detalle : '',
		aditionalReferences: userInfo.addressDetail ? userInfo.addressDetail.referencia : '',
		secondCountry: userInfo.secondCountry || '',
		isNewAddress: userInfo.nueva_direccion || false,
		secondState: {},
		secondCity: {},
		isNewSecondAddress: userInfo.second_nueva_direccion || false,
		secondKindOfStreet: !isAR && userInfo.secondNewAddress ?  findKindOfStreet(userInfo.secondNewAddress.tipoCalle) : '',
		secondStreet: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroTipo : '',
		secondStreetNumber: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numero.slice(2) : '',
		secondStreetSecondaryNumber: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroSecundario.slice(2) : '',
		secondNeighborhood: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.barrio : '',
		secondBlock: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.detalle : '',
		secondAditionalReferences: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.referencia : '',
		secondAddress: userInfo.secondAddress || '',
		secondAddressDetail: userInfo.secondAddressDetail || '',
		contactMethod: {
			emailContact: userInfo.emailContact,
			SMSContact: userInfo.smsContact,
		},

		/* Campos Argentina */
		addressARG: userInfo.address ?  userInfo.address.tipoCalle : '',
		height: userInfo.address ?  userInfo.address.numeroTipo : '',
		floor: userInfo.address ?  userInfo.address.numero === "null" ? 'Sin piso' : userInfo.address.numero : '',
		department: userInfo.address ?  userInfo.address.numeroSecundario === "null" ? 'Sin departamento' : userInfo.address.numeroSecundario : '',
		zipCode: userInfo.addressDetail ? userInfo.addressDetail.barrio : '',
		aditionalInformation: userInfo.addressDetail ? userInfo.addressDetail.detalle === "null" ? '' : userInfo.addressDetail.detalle : '',
		secondAddressARG: userInfo.secondNewAddress ?  userInfo.secondNewAddress.tipoCalle : '',
		secondHeight: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroTipo : '',
		secondFloor: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numero === "null" ? 'Sin piso' : userInfo.secondNewAddress.numero : '',
		secondDepartment: userInfo.secondNewAddress ?  userInfo.secondNewAddress.numeroSecundario === "null" ? 'Sin departamento' : userInfo.secondNewAddress.numeroSecundario : '',
		secondZipCode: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.barrio : '',
		secondAditionalInformation: userInfo.secondNewAddressDetail ? userInfo.secondNewAddressDetail.detalle === "null" ? '' : userInfo.secondNewAddressDetail.detalle : '',
	})

	const [isPhoneRegistered, setPhoneRegistered] = React.useState(false)
	const checkPhoneRegister = event => {
		if (
			userInfo.cellPhone &&
			userInfo.cellPhone?.toString() === event.target.value
		) {
			props.setFormChange(false)
			return false
		}

		const prefijo = props.locationInfo[countryPosition].prefijoTelefonico
		const data = {
			cellPhone: prefijo + event.target.value,
		}
		if (
			(locale === 'es-PE'
				? event.target.value.length === 9
				: locale === 'es-BO'
				? event.target.value.length === 8
				: event.target.value.length === 10) &&
			Number(event.target.value) !== userInfo.cellPhone
		) {
			RegisterService.checkPhone(data).then(res => {
				if (res.status === 400 && res.errorCode === 'DEM011') {
					setPhoneRegistered(true)
					props.setFormChange(false)
				}
			})
		}
	}

	const [isDocumentRegistered, setDocumentRegistered] = React.useState(false)
	const checkDocumentRegister = async (
		_value = formValues.document,
		_documentType = values.documentType
	) => {
		if (_value === values.document) {
			props.setFormChange(false)
			return false
		}

		if (_value && _value !== '' && _documentType) {
			const documentData = {
				typeId: _documentType,
				numberId: _value,
				userId: userInfo && userInfo.usuarioId ? userInfo.usuarioId : null,
			}

			const res = await RegisterService.checkDocument(documentData)
			if (res.status === 200) {
				setDocumentRegistered('')
			} else {
				setDocumentRegistered(res.userMessage)
				props.setFormChange(false)
			}
		}
	}

	const handleClick = prop => event => {
		const contactMethodLength = contactMethod.filter(
			method => method !== event.target.value
		).length

		if (
			contactMethodLength > 0 ||
			(contactMethodLength === 0 && event.target.checked)
		) {
			props.setFormChange(true)
			setContactMethod(
				event.target.checked
					? [...contactMethod, event.target.value]
					: contactMethod.filter(method => method !== event.target.value)
			)
		}
	}

	const onSubmit = data => {
		if (!values.documentType && process.env.LOCALE !== 'es-PR') {
			setError('documentType', 'manual', 'Debes seleccionar una opción')
			return false
		}
		if (!values.documentType && data.document) {
			setError('documentType', 'manual', 'Debes seleccionar una opción')
			return false
		}
		getNewDate()
		
		const floorPayload = data.floor === "Sin piso"? null : data.floor;
		const departmentPayload = data.department === "Sin departamento"? null : data.department;
		const aditionalInformationPayload = data.aditionalInformation === ""? null : data.aditionalInformation;

		const secondFloorPayload = data.secondFloor === "Sin piso"? null : data.secondFloor;
		const secondDepartmentPayload = data.secondDepartment === "Sin departamento"? null : data.secondDepartment;
		const secondAditionalInformationPayload = data.secondAditionalInformation === ""? null : data.secondAditionalInformation;
		
		const updatedInfo = {
			email: userInfo.email,
			birthdate: values.fullDate,
			cellPhone: prefijo + data.cellPhone,
			gender: values.gender,
			lastName: data.surname,
			mainAddress: isCO ? `${data.kindOfStreet}?${data.street}?# ${data.streetNumber}?- ${data.streetSecondaryNumber}` 
			: isAR ? `${data.addressARG}?${data.height}?${floorPayload}?${departmentPayload}` : data.address,
			mainCity:
				Number(values.country) !== CODE_PR_COUNTRY
					? values.city
						? values.city.codigo
						: null
					: values.state
					? values.state.codigo
					: null,
			mainCountry: values.country,
			mainCountryState:
				Number(values.country) !== CODE_PR_COUNTRY
					? values.state
						? values.state.codigo
						: null
					: null,
			name: data.name,
			roles,
			emailContact: contactMethod.includes('EMAIL'),
			smsContact: contactMethod.includes('SMS'),
			whatsappContact: contactMethod.includes('WPP'),
			shippingMainAddres: notiTena,
			mainAddressDetail: isCO ? `${data.neighborhood}?${data.block}?${data.aditionalReferences}` 
			: isAR ? `${data.zipCode}?${aditionalInformationPayload}` : data.mainAddressDetail,
			numberId: data.document ? data.document : null,
			typeId: data.document ? values.documentType : null,
		}

		if (secondLocationForm) {
			selectedLocation === 'mainLocation'
				? Object.assign(updatedInfo, {
					mainAddress:isCO ? `${data.kindOfStreet}?${data.street}?# ${data.streetNumber}?- ${data.streetSecondaryNumber}` 
					: isAR ? `${data.addressARG}?${data.height}?${floorPayload}?${departmentPayload}` : data.address,
					mainCity:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.city
								? Number(values.city.codigo)
								: null
							: values.state
							? Number(values.state.codigo)
							: null,
					mainCountry: Number(values.country),
					mainCountryState:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.state
								? Number(values.state.codigo)
								: null
							: null,
					secondAddress: isCO ? `${data.secondKindOfStreet}?${data.secondStreet}?# ${data.secondStreetNumber}?- ${data.secondStreetSecondaryNumber}` 
					: isAR ? `${data.secondAddressARG}?${data.secondHeight}?${secondFloorPayload}?${secondDepartmentPayload}` : data.secondAddress,
					secondCity:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.secondCity
								? Number(values.secondCity.codigo)
								: null
							: values.secondState
							? Number(values.secondState.codigo)
							: null,
					secondCountry: values.secondCountry,
					secondCountryState:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.secondState
								? Number(values.secondState.codigo)
								: null
							: null,
					secondAddressDetail: isCO ? `${data.secondNeighborhood}?${data.secondBlock}?${data.secondAditionalReferences}` 
					: isAR ? `${data.secondZipCode}?${secondAditionalInformationPayload}` : data.secondAddressDetail,
				})
				: Object.assign(updatedInfo, {
					mainAddress: isCO ? `${data.secondKindOfStreet}?${data.secondStreet}?# ${data.secondStreetNumber}?- ${data.secondStreetSecondaryNumber}`
						: isAR ? `${data.secondAddressARG}?${data.secondHeight}?${secondFloorPayload}?${secondDepartmentPayload}` : data.address,
					mainAddressDetail: isCO ? `${data.secondNeighborhood}?${data.secondBlock}?${data.secondAditionalReferences}`
						: isAR ? `${data.secondZipCode}?${secondAditionalInformationPayload}` : data.secondAddressDetail,
					secondCity:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.city
								? Number(values.city.codigo)
								: null
							: values.secondState
								? Number(values.state.codigo)
								: null,
					secondCountry: Number(values.country),
					secondCountryState:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.state
								? Number(values.city.codigo)
								: null
							: values.state
								? Number(values.state.codigo)
								: null,
					secondAddress: isCO ? `${data.kindOfStreet}?${data.street}?# ${data.streetNumber}?- ${data.streetSecondaryNumber}`
						: isAR ? `${data.addressARG}?${data.height}?${floorPayload}?${departmentPayload}` : data.secondAddress,
					mainCity:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.secondCity
								? Number(values.secondCity.codigo)
								: null
							: values.secondState
								? Number(values.secondState.codigo)
								: null,
					mainCountry: Number(values.secondCountry),
					mainCountryState:
						Number(values.country) !== CODE_PR_COUNTRY
							? values.secondState
								? Number(values.secondState.codigo)
								: null
							: null,
					secondAddressDetail: isCO ? `${data.neighborhood}?${data.block}?${data.aditionalReferences}`
						: isAR ? `${data.zipCode}?${aditionalInformationPayload}` : data.mainAddressDetail,
				}
				)
		}

		setLoaderVisible(true)
		if (isAdviser(SessionService.getIdTokenData())) {
			if (previouslyPurchased === null) {
				return false
			}

			updatedInfo.isCustomer = previouslyPurchased
			AdviserService.updateProfile(
				updatedInfo,
				res => {
					setLoaderVisible(false)
					if (res.data.status === 200) {
						props.setFormSubmit(true)
						const updatedData = JSON.stringify(res.data.data)
						sessionStorage.setItem('userData', updatedData)
						localStorage.setItem('userData', updatedData)
						setValues({...values,
							isNewAddress: true})
						values.isNewAddress=true
						props.setCurrentUser(
							res.data.data.name + ' ' + res.data.data.lastName
						)
					} else if (
						res.data.status === 400 &&
						res.data.errorCode === 'UEM050'
					) {
						alert(res.data.userMessage)
					} else {
						alert(res.data.userMessage)
					}

					setLoaderVisible(false)
				},
				error => {
					setLoaderVisible(false)
					console.log(error)
				}
			)
		} else {
			ProfileService.updateProfile(
				updatedInfo,
				res => {
					setLoaderVisible(false)
					if (res.data.status === 200) {
						props.setFormSubmit(true)
						const updatedData = JSON.stringify(res.data.data)
						props.actualStorage === 0
							? localStorage.setItem('userData', updatedData)
							: sessionStorage.setItem('userData', updatedData)
						props.setCurrentUser(
							`${res.data.data.name} ${res.data.data.lastName || ''}`
						)
						props.setFormChange(res.data.data)
						props.setStorageInfo(res.data.data)
					} else if (res.data.status === 400 || res.data.status === 500) {
						// on Error update account
					}
				},
				error => {
					setLoaderVisible(false)
					console.log(error)
				}
			)
		}
	}

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		triggerValidation({ name })
		if (!Object.is(userInitialInfo[name], value)) {
			props.setFormChange(true)
		} else {
			props.setFormChange(false)
		}
	}

	const handleSelectStreet = event => {
		const name = event.target.name
		const value = event.target.value
		const typedValue = name === 'country' ? Number(value) : value
		if (!Object.is(userInitialInfo[name], typedValue)) {
			setValue(name, typedValue)
			setValues({ ...values, [name]: typedValue })
			;(name === 'state' || name === 'secondState') &&
				getCities(name, typedValue)
			props.setFormChange(true)
			clearError('documentType')
		} else {
			props.setFormChange(false)
		}
	}
	const handleSelection = () => event => {
		const name = event.target.name
		const value = event.target.value
		const typedValue = name === 'country' ? Number(value) : value
		if (name === 'country') {
			const currentCountry = props.locationInfo.findIndex(
				node => Number(node.codigoDelPais) === typedValue
			)
			setCountryPosition(currentCountry)
			getCities(name, typedValue)
		}
		if (!Object.is(userInitialInfo[name], typedValue)) {
			setValue(name, typedValue)
			setValues({ ...values, [name]: typedValue })
			;(name === 'state' || name === 'secondState') &&
				getCities(name, typedValue)
			props.setFormChange(true)
			clearError('documentType')
		} else {
			props.setFormChange(false)
		}
	}

	const handleRoles = (active, role) => {
		let _roles = roles
		if (!active) {
			setRoles([...roles, role])
		} else {
			_roles = _roles.filter(rol => rol.nombreRol !== role.nombreRol)
			setRoles(_roles)
		}
		props.setFormChange(true)
	}

	const getNewDate = () => {
		if (formValues.date && values.month && formValues.year) {
			const dd = formValues.date < 10 ? '0' + formValues.date : formValues.date
			const mm = values.month < 10 ? '0' + values.month - 1 : values.month - 1
			const newDate = new Date(formValues.year, mm, dd)
			setValues({ ...values, fullDate: newDate })
		}
	}
	values.fullDate === '' && getNewDate()

	const getCities = (prop, stateValue) => {
		setValue(prop, stateValue)
		const newArray =
			locale === 'es-PR'
				? null
				: props.locationInfo[countryPosition].ciudades.filter(
						city => city.codigoDepartamento === stateValue.codigo
				  )
		prop === 'state' ? setCities(newArray) : setSecondCities(newArray)
		if (prop === 'country') {
			setCities([])
		}
	}

	const addLocationForm = () => {
		setSecondLocationForm(true)
	}

	const selectLocation = _location => {
		setLocation(_location)
		props.setFormChange(true)
	}

	useEffect(() => {
		let currentCountryState
		let currentCity
		let currentSecondCountryState
		let currentSecondCity

		const setCitiesAsync = async cities => {
			await setCities(cities)
		}

		(userInfo.mainCountryState || userInfo.secondCountryState) &&
			props.locationInfo[countryPosition].departamentos.map(countryState => {
				
				if (userInfo.mainCountryState === countryState.codigo) {
					getCities('state', countryState)
					currentCountryState = countryState
				}
				if (userInfo.secondCountryState === countryState.codigo) {
					getCities('secondState', countryState)
					currentSecondCountryState = countryState
				}
				return countryState
			})
						
		Number(props.locationInfo[countryPosition].codigoDelPais) !==
			CODE_PR_COUNTRY && props.locationInfo[countryPosition].ciudades
			? (userInfo.mainCity || userInfo.secondCity) &&
			  props.locationInfo[countryPosition].ciudades.map(city => {

					if (userInfo.mainCity === city.codigo) {
						currentCity = city
					}
					if (userInfo.secondCity === city.codigo) {
						currentSecondCity = city
					}
					return city
			  })
			: (userInfo.mainCity || userInfo.secondCity) &&
			  props.locationInfo[countryPosition].departamentos.map(dep => {
					if (userInfo.mainCity === dep.codigo) {
						currentCountryState = dep
					}
					if (userInfo.secondCity === dep.codigo) {
						currentSecondCountryState = dep
					}
					return dep
			  })
		if (
			userInfo.secondAddress &&
			userInfo.secondCountry &&
			userInfo.secondCountryState &&
			userInfo.secondCity
		) {
			setSecondLocationForm(true)
		}
		if (props.locationInfo[countryPosition].ciudades) {
			setCitiesAsync(props.locationInfo[countryPosition].ciudades)
		}
		setValues({
			...values,
			city: currentCity,
			state: currentCountryState,
			secondCity: currentSecondCity || '',
			secondState: currentSecondCountryState || '',
		})
	}, [userInfo])

	return (
		<div className='f-user-account-container'>
			<form onSubmit={handleSubmit(onSubmit)} className='f-user-form-wrapper'>
				<section className='f-user-section'>
					<div className='f-title-wrapper'>
						<h3 className='f-section-title'>Información básica</h3>
						<p className='f-section-subtitle'>Datos personales</p>
					</div>
					<UserGeneralInfoForm
						changeModalEmail={() => {
							setIsVisbleModalEmail(state => !state)
						}}
						userFormInfo={props.userFormInfo.formularioInformacionPersonal}
						errors={errors}
						register={register}
						handleChange={handleChange}
						handleSelection={handleSelection}
						values={values}
						setValue={setValue}
						formValues={formValues}
						formState={formState}
						getNewDate={() => getNewDate}
						checkPhoneRegister={checkPhoneRegister}
						checkDocumentRegister={checkDocumentRegister}
						isPhoneRegistered={isPhoneRegistered}
						isDocumentRegistered={isDocumentRegistered}
						setPhoneRegistered={setPhoneRegistered}
						setDocumentRegistered={setDocumentRegistered}
						isMyAccount={true}
						isFirefox={isFirefox}
						documentInfo={documentInfo}
						isAdviser={isAdviser(SessionService.getIdTokenData())}
						setError={setError}
					/>
				</section>
				{userInfo.roles &&
				(userInfo.roles[0].nombreRol ===
					constants.ROL_COLABORADOR_INSTITUCION.nombreRol ||
					userInfo.roles[0].nombreRol ===
						constants.ROL_ADMINISTRADOR_INSTITUCION.nombreRol) ? (
					<></>
				) : (
					<section className='f-user-section f-user-section-cards'>
						<div className='f-title-wrapper'>
							<h3 className='f-section-title'>Mi perfil TENA</h3>
							<p className='f-section-subtitle'>
								Aquí podrás modificar el perfil con el cual te registraste o
								agregar un nuevo perfil a tu usuario.
							</p>
						</div>
						<UserRolesFormInfo
							registerRolesInfo={props.registerRolesInfo}
							rolesArray={roles}
							setNewRole={(active, role) => handleRoles(active, role)}
						/>
					</section>
				)}
				<section className='f-user-section'>
					<div className='f-title-wrapper'>
						<h3 className='f-section-title'>Mi dirección</h3>
						<p className='f-section-subtitle'>
							Aquí podrás agregar o modificar una dirección para recibir
							información acerca de los productos y beneficios TENA.
						</p>
					</div>
					{
						isAR? 
						(<UserLocationInfoFormARG
						userFormInfo={props.userFormInfo.formularioInformacionPersonal}
						errors={errors}
						register={register}
						handleChange={handleChange}
						handleSelection={handleSelection}
						values={values}
						formValues={formValues}
						formState={formState}
						citiesArray={citiesArray}
						isMyAccount={true}
						addLocationForm={addLocationForm}
						selectLocation={selectLocation}
						selectedLocation={selectedLocation}
						secondLocationForm={secondLocationForm}
						location='mainLocation'
						locationInfo={props.locationInfo}
						countryPosition={countryPosition}
						clearError={clearError}
						/>)
						:
						(<UserLocationInfoForm
							userFormInfo={props.userFormInfo.formularioInformacionPersonal}
							control={control}
							errors={errors}
							register={register}
							handleChange={handleChange}
							handleSelection={handleSelection}
							handleSelectStreet={handleSelectStreet}
							values={values}
							setValue={setValue}
							formValues={formValues}
							formState={formState}
							citiesArray={citiesArray}
							isMyAccount={true}
							addLocationForm={addLocationForm}
							selectLocation={selectLocation}
							selectedLocation={selectedLocation}
							secondLocationForm={secondLocationForm}
							location='mainLocation'
							locationInfo={props.locationInfo}
							countryPosition={countryPosition}
							/>)
					}
				</section>
				{secondLocationForm && (
					<section className='f-user-section'>
						<h3 className='f-section-title'>Otra dirección</h3>
						{
						isAR? 
						(<UserLocationInfoFormARG
							userFormInfo={props.userFormInfo.formularioInformacionPersonal}
							errors={errors}
							register={register}
							handleChange={handleChange}
							handleSelection={handleSelection}
							values={values}
							formValues={formValues}
							formState={formState}
							citiesArray={citiesSecondArray}
							isMyAccount={true}
							addLocationForm={addLocationForm}
							selectLocation={selectLocation}
							selectedLocation={selectedLocation}
							secondLocationForm={secondLocationForm}
							location='secondaryLocation'
							locationInfo={props.locationInfo}
							countryPosition={countryPosition}
							clearError={clearError}
						/>)
						:
						(<UserLocationInfoForm
							userFormInfo={props.userFormInfo.formularioInformacionPersonal}
							control={control}
							errors={errors}
							register={register}
							handleChange={handleChange}
							handleSelection={handleSelection}
							values={values}
							setValue={setValue}
							formValues={formValues}
							formState={formState}
							citiesArray={citiesSecondArray}
							handleSelectStreet={handleSelectStreet}
							isMyAccount={true}
							addLocationForm={addLocationForm}
							selectLocation={selectLocation}
							selectedLocation={selectedLocation}
							secondLocationForm={secondLocationForm}
							location='secondaryLocation'
							locationInfo={props.locationInfo}
							countryPosition={countryPosition}
						/>)
					}
					</section>
				)}
				<section className='f-user-section'>
					<div className='f-user-form'>
						{isNotiTenaVisible ? (
							<div className='f-samples-wrapper'>
								<div className='f-title-wrapper'>
									<p className='f-samples-title'>
										¿Quieres recibir en tu casa los beneficios que tenemos para
										ti?
									</p>
								</div>
								<Radio
									text='Si'
									isActive={notiTena}
									setActive={() => {
										setNotiTena(true)
										props.setFormChange(true)
									}}
								/>
								<Radio
									text='No'
									isActive={!notiTena}
									setActive={() => {
										setNotiTena(false)
										props.setFormChange(true)
									}}
								/>
							</div>
						) : null}
					</div>
				</section>

				{(localStorage.getItem('tokenData') ||
					sessionStorage.getItem('tokenData')) &&
				isAdviser(SessionService.getIdTokenData()) ? (
					<section className='f-user-section'>
						<div className='f-user-form f-alert-commerce-container'>
							<div className='f-samples-wrapper'>
								<div className='f-title-wrapper'>
									<p className='f-samples-title'>
										¿Has comprado productos TENA en nuestro e-commerce?
									</p>
								</div>
								<Radio
									text='Si'
									isActive={previouslyPurchased}
									setActive={() => {
										setPreviouslyPurchased(true)
										props.setFormChange(true)
									}}
								/>
								<Radio
									text='No'
									isActive={
										previouslyPurchased !== null && !previouslyPurchased
									}
									setActive={() => {
										setPreviouslyPurchased(false)
										props.setFormChange(true)
									}}
								/>
							</div>
							{previouslyPurchased === null && (
								<Alert
									alertType='error'
									description='Debes seleccionar una opción'
								/>
							)}
						</div>
					</section>
				) : null}

				<section className='f-user-section'>
					<div className='f-user-form'>
						<div className='f-activation-wrapper'>
							<div className='f-activation-title-wrapper'>
								<p className='f-activation-title'>
									¿Por qué medio deseas que te contacten?
								</p>
							</div>
							<FormControl component='fieldset'>
								<div className='f-activation-controls'>
									<FormControlLabel
										control={
											<Checkbox
												checked={contactMethod.includes('EMAIL')}
												onClick={handleClick()}
												value={'EMAIL'}
												className='f-rol-check'
											/>
										}
										label='Mi correo'
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={contactMethod.includes('SMS')}
												onClick={handleClick()}
												value={'SMS'}
												className='f-rol-check'
											/>
										}
										label='SMS'
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={contactMethod.includes('WPP')}
												onClick={handleClick()}
												value={'WPP'}
												className='f-rol-check'
											/>
										}
										label='WhatsApp'
									/>
								</div>
							</FormControl>
						</div>
					</div>
				</section>
				<ButtonLoading
					isEnabled={!props.hasFormChanged}
					button={
						<Button
							id='f-login-submit-btn'
							type='input'
							classname='f-button f-button--primary'
							text={convertText('ACTUALIZAR DATOS')}
						/>
					}
					isShowingLoader={isLoaderVisible}
					bgClass='primary'
				/>
			</form>
			{isVisbleModalEmail && (
				<ChangeEmailContainer
					closeModal={() => {
						setIsVisbleModalEmail(false)
					}}
				/>
			)}
			{passwordModal && (
				<ChangePassContainer
					type={0}
					closeModal={() => setPasswordModal(false)}
					actualStorage={props.actualStorage}
					formValues={formValues}
				/>
			)}
		</div>
	)
}

export default UserAccountContainer
