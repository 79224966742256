import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Controller } from 'react-hook-form'
import Alert from '../alert/alert'
import Icon from '../Icon/Icon'

const SelectMaterial = ({
	styles,
	isStreet = false,
	styleLabel,
	styleSelect,
	items,
	label,
	name,
	value,
	rules,
	errors,
	setValue = () => {},
	control,
	change = () => {},
}) => {
	return (
		<FormControl
			className={`f-form-input f-input-material  ${styles}`}
			variant='outlined'
		>
			<InputLabel
				htmlFor={name}
				className={{ styleLabel }}
				error={errors[name]}
			>
				{label}
			</InputLabel>
			<Controller
				rules={rules}
				control={control}
				name={name}
				defaultValue=''
				onChange={([event]) => {
					setValue(event.target.value)
					change(event)
					return event.target.value
				}}
				as={
					<Select
						className={styleSelect}
						readOnly={!setValue}
						MenuProps={{ disableScrollLock: true }}
						inputProps={{
							id: { name },
							name: { name },
						}}
					>
						{items.map(item => (
							<MenuItem key={item.key} value={!isStreet ? item.key : item.value}>
								{item.name || item.value}
							</MenuItem>
						))}
					</Select>
				}
			/>
			<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
			{errors[name] && (
				<Alert alertType='error' description={errors[name].message} />
			)}
		</FormControl>
	)
}

export default SelectMaterial
