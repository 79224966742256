import React,{ useState } from 'react'
import {
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
} from '@material-ui/core'
import SelectMaterial from '../../../shared/components/atoms/select-material/select-material'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import InputMaterialWithCheck from "../../../shared/components/atoms/input-material-with-check/input-material-with-check"
import Icon from '../../../shared/components/atoms/Icon/Icon'
import {
	ADDRESS_PATTERN,
	NAME_MIN_LENGTH,
	NUMBER_PATTERN,
	EMAIL_PATTERN,
	CELLPHONE_LENGTH,
	QUANTITY_MIN,
	NO_SPECIAL_CHARACTERS_PATTERN,
	INSTITUTION_NAME_PATTERN,
	FLOOR_PATTERN, 
	DEPARTMENT_PATTERN, 
	ADITIONAL_INFO_PATTERN
} from '../../../shared/state/constants/appConstants'
import './institutionGeneralInfoForm.scss'

const InstitutionGeneralInfoForm = ({register, control, handleChange, handleSelection, handleSelectStreet, checkInstitutionId, 
	checkCellPhoneData, errors, institutionFormInfo, formValues, formState, citiesArray, values, setValue, setValues, setActive, isDataActive, 
	isMyAccount, locationInfo, countryPosition, country, checkRegisteredData, clearError}) => {
	
	const {
		nombreInstitucion,
		nit,
		numeroTelefonicoInstitucion,
		correoElectronico,
		pais,
		departamento,
		ciudad,
		direccion,
	} = institutionFormInfo

	const currentCountry = locationInfo[countryPosition]
	const inputLabel = React.useRef(null)
	const [labelWidth] = React.useState(0)
	const [kindOfStreet, setKindOfStreet] = useState(values.kindOfStreet )

	const [checkFloor, setCheckFloor] = useState(false)
  const [checkDepartment, setCheckDepartment] = useState(false)

	const cellPhoneLength = process.env.CELL_DIG
	const isCO = process.env.LOCALE === 'es-CO'
	const isAR = process.env.LOCALE === 'es-AR'

	const valueAncientAddress = () =>{
		const fullAddress = `${values.address} ${values.mainAddressDetail}`.toString()
		return fullAddress
	}
	
	return (
		<FormGroup className='f-form-section'>
			<InputMaterial
				label={nombreInstitucion}
				errors={errors}
				name='name'
				type='text'
				id='f-institute-name'
				change={handleChange}
				classes='f-form-input f-form-input-large'
				autoComplete={false}
				register={register({
					required: {
						value: true,
						message: 'El nombre de la institución es requerido.',
					},
					pattern: {
						value: INSTITUTION_NAME_PATTERN,
						message: 'El nombre ingresado es inválido.',
					},
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'El nombre debe tener mínimo 3 caracteres.',
					},
				})}
			/>
			<InputMaterial
				label={nit}
				errors={errors}
				disabled={
					values.numberId !== ""
					// && !formState.touched.includes('nit')
				}
				messageError={
					isDataActive.nit
						? 'Este identificador ya se encuentra registrado.'
						: null
				}
				name='nit'
				type='text'
				id='f-institute-nit'
				change={e => {
					if (e.target.value !== null && e.target.value.length > 0) {
						const newValue = e.target.value.replace(/\D/g, '')
						setValue('nit', newValue)
					}
					setActive({ ...isDataActive, nit: false })
					handleChange(e)
				}}
				classes='f-form-input'
				blurEvent={checkInstitutionId}
				autoComplete={false}
				register={register({
					required: { value: true, message: 'Tu número de NIT es requerido.' },
					pattern: { value: NUMBER_PATTERN, message: 'Ingresa sólo números.' },
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'Ingresa mínimo 3 cifras.',
					},
					maxLength: {
						value: CELLPHONE_LENGTH,
						message: 'Ingresa máximo 10 cifras.',
					},
				})}
			/>

			<InputMaterial
				label='Número de sedes'
				errors={errors}
				disabled={
					formValues.nit === '' &&
					//!formState.touched.includes('headquarters') &&
					!isMyAccount
				}
				name='headquarters'
				type='text'
				id='f-institute-headquarters'
				change={e => {
					if (e.target.value !== null && e.target.value.length > 0) {
						const newValue = e.target.value.replace(/\D/g, '')
						setValue('headquarters', newValue)
					}
					setActive({ ...isDataActive, nit: false })
					handleChange(e)
				}}
				classes='f-form-input'
				autoComplete={false}
				register={register({
					required: { value: true, message: 'Este campo es requerido.' },
					pattern: { value: NUMBER_PATTERN, message: 'Ingresa sólo números.' },
					minLength: {
						value: QUANTITY_MIN,
						message: 'Ingresa una cantidad válida.',
					},
				})}
			/>

			<InputMaterial
				blurEvent={checkCellPhoneData}
				errors={errors}
				messageError={
					isDataActive.cellPhone
						? 'Este número celular ya se encuentra registrado.'
						: null
				}
				disabled={
					formValues.cellPhone === '' &&
					!formState.touched.includes('cellPhone') &&
					!isMyAccount
				}
				name='cellPhone'
				change={e => {
					if (e.target.value !== null && e.target.value.length > 0) {
						const newValue = e.target.value.replace(/\D/g, '')
						setValue('cellPhone', newValue)
					}
					setActive({ ...isDataActive, cellPhone: false })
					handleChange(e)
				}}
				label={numeroTelefonicoInstitucion}
				type='text'
				autoComplete={false}
				register={register({
					required: { value: true, message: 'Tu número celular es requerido.' },
					pattern: { value: NUMBER_PATTERN, message: 'Ingresa sólo números.' },
					minLength: {
						value: cellPhoneLength,
						message: `Un número celular generalmente tiene ${cellPhoneLength} dígitos.`,
					},
					maxLength: {
						value: cellPhoneLength,
						message: `Un número celular generalmente tiene ${cellPhoneLength} dígitos.`,
					},
				})}
				classes='f-form-input'
				id='f-institute-cellPhone'
			/>

			<InputMaterial
				change={e => {
					setActive({ ...isDataActive, email: false })
					handleChange(e)
				}}
				errors={errors}
				blurEvent={checkRegisteredData}
				messageError={
					isDataActive.email
						? 'Este correo ya se encuentra registrado.'
						: null
				}
				disabled={true}
				name='email'
				type='text'
				autoComplete={false}
				classes='f-form-input'
				id='f-institution-helper-email'
				label={correoElectronico}
				register={register({
					required: { value: true, message: 'El correo es requerido.' },
					pattern: {
						value: EMAIL_PATTERN,
						message: 'El correo ingresado es inválido.',
					},
					minLength: {
						value: 8,
						message: 'El email debe tener mínimo 8 caracteres.',
					},
				})}
			/>
			<FormControl
				variant='outlined'
				className={`${
					currentCountry && currentCountry.codigoDelPais === '12'
						? 'f-form-input f-form-input-select--two'
						: 'f-form-input f-form-input-select'
				}`}
			>
				<InputLabel
					filled={true}
					ref={inputLabel}
					htmlFor='f-institute-country'
				>
					{pais}
				</InputLabel>
				<Select
					value={values.country}
					disabled={true}
					input={
						<OutlinedInput
							labelWidth={labelWidth}
							name='country'
							id='f-institute-country'
						/>
					}
				>
					{currentCountry && (
						<MenuItem value={currentCountry.codigoDelPais}>
							{currentCountry.titulo}
						</MenuItem>
					)}
				</Select>
			</FormControl>

			<FormControl
				variant='outlined'
				className={`${
					currentCountry && currentCountry.codigoDelPais === '12'
						? 'f-form-input f-form-input-select--two'
						: 'f-form-input f-form-input-select'
				}`}
				disabled={
					formValues.cellPhone === '' &&
					!formState.touched.includes('country') &&
					!isMyAccount
				}
			>
				<InputLabel ref={inputLabel} htmlFor='f-institute-countryState'>
					{departamento}
				</InputLabel>
				<Select
					value={values.countryState}
					onChange={handleSelection()}
					input={
						<OutlinedInput
							labelWidth={labelWidth}
							name={'countryState'}
							id='f-institute-countryState'
						/>
					}
				>
					{locationInfo[countryPosition] &&
						locationInfo[countryPosition].departamentos.map(
							countryState => (
								<MenuItem
									key={'countryState' + countryState.codigo}
									value={countryState}
								>
									{countryState.nombre}
								</MenuItem>
							)
						)}
				</Select>
				<Icon
					icon={`icon-chevron-down lonchmara ${
						formValues.country === '' &&
						!formState.touched.includes('countryState')
							? 'Mui-disabled'
							: 'f-icon'
					}`}
					size='0-7'
					tagtype='i'
				/>
			</FormControl>
			{currentCountry && currentCountry.codigoDelPais === '12' ? null : (
				<FormControl
					variant='outlined'
					className='f-form-input f-form-input-select'
					disabled={
						formValues.countryState === '' &&
						!formState.touched.includes('city') &&
						!isMyAccount
					}
				>
					<InputLabel ref={inputLabel} htmlFor='f-institute-city'>
						{ciudad}
					</InputLabel>
					<Select
						value={values.city}
						onChange={handleSelection()}
						input={
							<OutlinedInput
								labelWidth={labelWidth}
								name='city'
								id='f-institute-city'
							/>
						}
					>
						{citiesArray.map(city => (
							<MenuItem key={'city' + city.codigo} value={city}>
								{city.nombre}
							</MenuItem>
						))}
					</Select>
					<Icon
						icon={`icon-chevron-down lonchmara ${
							formValues.countryState === '' &&
							!formState.touched.includes('city')
								? 'Mui-disabled'
								: ''
						}`}
						size='0-7'
						tagtype='i'
					/>
				</FormControl>
			)}
			{isCO? (
				<div className='f-form-addresss'>
					{(!values.isNewAddress) && (
						<>
							<InputMaterial
								change={handleChange}
								disabled={true}
								errors={errors}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-ancientAddress'
								id={'f-user-neighborhood'}
								label={'Dirección Antigua'}
								value={valueAncientAddress().replaceAll('?', ' ')}
							/>
							<p className='f-section-subtitle'>
								Por favor actualiza tu dirección
							</p>
						</>)}
						<div className='f-form-addresss-group'>
							<SelectMaterial
								styles='f-form-input f-form-input--institute f-select-input-sample--street'
								change={(event) => {
									handleChange(event)
									setValue('kindOfStreet',event.target.value)
								}}
								name="kindOfStreet"
								isStreet={true}
								label="Tipo de calle *"
								errors={errors}
								setValue={value => { setKindOfStreet(value) }}
								rules={{
									required: {
										value: true,
										message: "Este campo es requerido.",
									},
								}}
								control={control}
								items={currentCountry.tipoDeCalle.map(({ idCalle, name }) => ({
									key: idCalle,
									value: name,
								}))}
							/>

							<InputMaterial
								errors={errors}
								name={'numberStreet'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-medium-address f-form-input-medium-address-margin'
								id='f-user-street'
								label={kindOfStreet}
								change={(event) => {
									handleChange(event)
								}}
								register={register({
									required: {
										value: true,
										message: 'Campo requerido.',
									},maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
							<InputMaterial
								isNumberAddress = {true}
								characterAddress='# '
								change={e => {
									handleChange(e)
								}}
								errors={errors}
								name={'firstNumber'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-medium-address'
								id='f-user-number'
								label={'Número'}
								register={register({
									required: { value:  kindOfStreet !== 'Manzana', message: "El campo es requerido." },
									maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
							<InputMaterial
								isNumberAddress = {true}
								characterAddress='- '
								change={(event) => {
									handleChange(event)
								}}
								errors={errors}
								name={'secondaryNumber'}
								classes='f-form-input f-form-input-medium-address'
								register={register({
									required: {
										value: kindOfStreet !== 'Manzana',
										message: 'Campo obligatorio.',
									}, maxLength: {
										value: 20,
										message: 'El campo debe tener máximo 20 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
							<InputMaterial
								change={(e) => {
									setValue('neighborhood',e.target.value)
									handleChange(e)
								}}
								errors={errors}
								name={'neighborhood'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-neighborhood f-form-input-neighborhood-margin'
								id='f-user-secondNumber'
								label={'Barrio'}
								register={register({
									required: {
										value: true,
										message: 'Campo obligatorio.',
									},maxLength: {
										value: 50,
										message: 'El campo debe tener máximo 120 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
						</div>
						<div className='f-form-addresss-group-secondary'>
							<InputMaterial
								change={(e) => {
									setValue('block',e.target.value)
									handleChange(e)
								}}
								errors={errors}
								name={'block'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-address-complementary'
								id='f-user-block'
								label={'Bloque o torre / Apartamento'}
								register={register({
									required: {
										value: true,
										message: 'Campo requerido.',
									},maxLength: {
										value: 50,
										message: 'El campo debe tener máximo 50 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
							<InputMaterial
								change={(e) => {
									setValue('detalle',e.target.value)
									handleChange(e)
								}}
								errors={errors}
								name={'aditionalReferences'}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-address-complementary'
								id={'aditionalInformation'}
								label={'Referencias adicionales'}
								register={register({
									maxLength: {
										value: 50,
										message: 'El campo debe tener máximo 50 caracteres.',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									}
								})}
							/>
						</div>
				</div>
			) : isAR? (
				<div className='f-form-addresss'>
					{(!values.isNewAddress) && (
						<>
							<InputMaterial
								change={handleChange}
								disabled={true}
								errors={errors}
								type='text'
								autoComplete={false}
								classes='f-form-input f-form-input-ancientAddress'
								id={'f-user-neighborhood'}
								label={'Dirección Antigua'}
								value={valueAncientAddress().replaceAll('?', ' ')}
							/>
							<p className='f-section-subtitle'>
								Por favor actualiza tu dirección
							</p>
						</>)}
						
						<div className='f-form-group'>
							<InputMaterial
								errors={errors}
								name='addressARG'
								type='text'
								autoComplete={false}
								classes='f-input-material-address--direccion'
								label="Dirección *"
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									minLength: {
										value: 5,
										message: 'El campo debe tener mínimo 5 caracteres',
									},
									pattern: {
										value: ADDRESS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
							/>
							<InputMaterial
								name='height'
								autoComplete={false}
								classes='f-input-material-address--height'
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 6,
										message: 'El campo debe tener máximo 6 dígitos',
									},
									pattern: {
										value: NUMBER_PATTERN,
										message: 'Solo son válidos números',
									},
								})}
								label={'Altura *'}
								errors={errors}
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterialWithCheck
								name='floor'
								id='floor'
								text='piso'
								classes='f-input-material-address--floor'
								change={handleChange}
								autoComplete={false}
								register={register({
									required: {
										value: !checkFloor,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 12,
										message: 'El campo debe tener máximo 12 caracteres',
									},
									pattern: {
										value: FLOOR_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Piso *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setCheckFloor}
								whatFormIs="actualizacion"
								formValue={formValues.floor}
							/>
							<InputMaterialWithCheck
								name='department'
								classes='f-input-material-address--department'
								id='department'
								text='departamento'
								autoComplete={false}
								change={handleChange}
								register={register({
									required: {
										value: !checkDepartment,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value:
											formValues.department === 'Sin departamento' ? 16 : 5,
										message: 'El campo debe tener máximo 5 caracteres',
									},
									pattern: {
										value: DEPARTMENT_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Departamento *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setCheckDepartment}
								whatFormIs="actualizacion"
								formValue={formValues.department}
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterial
								name='zipCode'
								autoComplete={false}
								classes='f-input-material-address--zip-code'
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 8,
										message: 'El campo debe tener máximo 8 caracteres',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Código postal *'}
								errors={errors}
							/>
							<InputMaterial
								name='aditionalInformation'
								autoComplete={false}
								classes='f-input-material-address--aditional-info'
								change={handleChange}
								register={register({
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									pattern: {
										value: ADITIONAL_INFO_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Información adicional'}
								errors={errors}
							/>
						</div>
				</div>
			)
			:
			(
				<InputMaterial
				change={handleChange}
				disabled={
					formValues.city === '' &&
					((locationInfo[countryPosition] &&
						locationInfo[countryPosition].codigoDelPais !== '12') ||
						(formValues.countryState === '' &&
							(locationInfo[countryPosition] &&
								locationInfo[countryPosition].codigoDelPais ===
									'12')))
					// && !formState.touched.includes('address')
				}
				errors={errors}
				name='address'
				type='text'
				autoComplete={false}
				classes='f-form-input f-form-input-full'
				id='f-institute-address'
				label={direccion}
				register={register({
					required: { value: true, message: 'La dirección es requerida.' },
					minLength: {
						value: 15,
						message: 'La dirección debe tener mínimo 15 caracteres.',
					},
					maxLength: {
						value: 300,
						message: 'La dirección debe tener máximo 300 caracteres.',
					},
					pattern: {
						value: ADDRESS_PATTERN,
						message: 'La dirección es inválida.',
					},
				})}
			/>
			)}
			
		</FormGroup>
	)
}

export default InstitutionGeneralInfoForm
