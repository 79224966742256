import { useState, useEffect } from 'react'
import SessionService from '../services/session'
import AuthService from '../services/authentication'
import { isAdviser } from '../state/utils/isAdviser'

export default function usePointsMode() {
	useEffect(() => {
		if (
			localStorage.getItem('tokenData') ||
			sessionStorage.getItem('tokenData')
		) {
			if (!isAdviser(SessionService.getIdTokenData())) {
				handleUpdatePoints()
			}
		}
	}, [])

	const [currentPoints, setCurrentPoints] = useState(0)
	const [currentUser, setCurrentUser] = useState('')

	const handleUpdatePoints = () => {
		if (SessionService.getUserData()) {
			AuthService.userInfo(SessionService.getIdTokenData()).then(resp => {
				if (resp.status === 200) {
					localStorage.getItem('userData')
						? localStorage.setItem('userData', JSON.stringify(resp.data))
						: sessionStorage.setItem('userData', JSON.stringify(resp.data))
					let fullName = resp.data.name

					if (
						resp.data.roles &&
						resp.data.roles[0]?.nombreRol !== 'INSTITUCION'
					) {
						fullName = `${resp.data.name} ${resp.data.lastName || ''}`
					}
					localStorage.getItem('userName')
						? localStorage.setItem('userName', fullName)
						: sessionStorage.setItem('userName', fullName)
					setCurrentUser(fullName)
					setCurrentPoints(resp.data.points)
				}
			})
		}
	}

	return {
		currentPoints,
		currentUser,
		handleUpdatePoints,
		setCurrentPoints,
		setCurrentUser,
	}
}
