import React from 'react'
import PropTypes from 'prop-types'
import './spacer.scss'

/* Spacer -------------------------------------------------------------------------

    - px <number>:      height of spacer in pixels.

    - hideon <string>:  ref to a breakpoint for hiding the spacer.

                        Based on breakpoints at './../../../_shared/Variables.scss'

                        small:          440px
                        s-medium:       570px
                        medium:         761px
                        large:          960px
                        x-large:        1024px
                        xx-large:       1250px
                        xxx-large:      1440px
                        high-quality:   1700px

---------------------------------------------------------------------------------- */

const Spacer = ({ px, hideon }) => {
	return (
		<div
			className={`f-spacer f-hide-on-${hideon}`}
			style={{ height: `${px}px` }}
		>
			{' '}
		</div>
	)
}

Spacer.propTypes = {
	px: PropTypes.number,
	hideon: PropTypes.string,
}

export default Spacer
