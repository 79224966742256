import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import ButtonLoading from '../../../shared/components/molecules/buttonLoading/buttonLoading'
import Button from '../../../shared/components/atoms/Button/Button'
import { EMAIL_PATTERN } from '../../../shared/state/constants/appConstants'
import './modal-change-email.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ModalChangeEmail = ({ onSubmit, errorEmail, isLoading, clearError }) => {
	const { register, watch, handleSubmit, errors } = useForm()
	const email = watch('email')

	useEffect(() => {
		clearError()
	}, [email])

	return (
		<div className='f-change-email' onSubmit={handleSubmit(onSubmit)}>
			<h1 className='f-change-email__title'>CAMBIAR CORREO ELECTRÓNICO</h1>
			<p className='f-change-email__description'>
				¡Nos encanta que estés en contacto con nosotros!. Has solicitado
				modificar o cambiar tu correo electrónico de acceso a TENA, por favor
				escribe el nuevo correo electrónico con el que deseas seguir ingresando.
			</p>
			<form className='f-change-email-form'>
				<InputMaterial
					name='email'
					label='Nuevo correo electrónico'
					messageError={errorEmail}
					errors={errors}
					register={register({
						required: {
							value: true,
							message: 'El correo es requerido.',
						},
						pattern: {
							value: EMAIL_PATTERN,
							message: 'El correo ingresado es inválido.',
						},
						minLength: {
							value: 8,
							message: 'El email debe tener mínimo 8 caracteres.',
						},
					})}
				/>
				<InputMaterial
					name='email_confirm'
					label='Confirma el correo electrónico'
					errors={errors}
					register={register({
						validate: value =>
							value === email || 'Los correos ingresados no coinciden',
					})}
				/>

				<ButtonLoading
					isEnabled={false}
					button={
						<Button
							type='input'
							classname='f-button f-button--primary'
							text={convertText('CAMBIAR CORREO')}
						/>
					}
					isShowingLoader={isLoading}
					bgClass='primary'
				/>
			</form>
		</div>
	)
}

export default ModalChangeEmail
