import React, { useState } from 'react'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import Button from '../../../shared/components/atoms/Button/Button'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import ModalChangeEmail from '../../molecules/modal-change-email/modal-change-email'
import ProfileService from '../../../shared/services/profile'
import AlertMessagge from '../../../shared/components/molecules/alertMessagge/alertMessagge'
import iconCheck from '../../../shared/assets/images/icon-check.svg'
import './change-email-container.scss'

const ChangeEmailContainer = ({ closeModal }) => {
	const [step, setStep] = useState(1)
	const [isLoading, setIsLoading] = useState()
	const [isLoadingDelete, setIsLoadingDelete] = useState()
	const [errorEmail, setErrorEmail] = useState()
	const { homeLink } = useHomeLinkMode()

	const createChangeEmail = async formValues => {
		try {
			setIsLoading(true)
			const body = { email: formValues.email }
			const res = await ProfileService.changeEmail(body)
			if (res.data.status === 200) {
				confirmChangeEmail()
			} else if (res.data.status === 400) {
				setIsLoading(false)
				setErrorEmail(res.data.userMessage)
			}
		} catch (e) {
			console.error(e)
		}
	}

	const confirmChangeEmail = async () => {
		try {
			setIsLoading(true)
			const res = await ProfileService.confirmChangeEmail()
			setIsLoading(false)
			if (res.data.status === 200) {
				setStep(2)
			} else {
				setErrorEmail(res.data.userMessage)
				delateChangeEmail()
			}
		} catch (e) {
			console.error(e)
			delateChangeEmail()
		}
	}

	const delateChangeEmail = async () => {
		try {
			setIsLoadingDelete(true)
			const res = await ProfileService.cancelEmailChange()
			if (res.data.status === 200) {
				closeModal()
			}
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoadingDelete(false)
		}
	}

	const onFinishChange = () => {
		localStorage.removeItem('tokenData')
		localStorage.removeItem('userName')
		localStorage.removeItem('userData')
		localStorage.removeItem('_grecaptcha')
		sessionStorage.clear()
		window.location.href = homeLink + 'autenticacion/'
	}

	return (
		<LayoutModal>
			<div className='f-modal-change-email'>
				{step === 1 && (
					<ModalChangeEmail
						clearError={() => {
							setErrorEmail(null)
						}}
						isLoading={isLoading || isLoadingDelete}
						errorEmail={errorEmail}
						onSubmit={createChangeEmail}
					/>
				)}
				{step === 2 && (
					<div className='f-container-success'>
						<AlertMessagge
							icon={<img src={iconCheck} alt='check-correct' />}
							messagge='TU  CORREO ELECTRÓNICO HA SIDO CAMBIADO CON ÉXITO'
							description='Para continuar debes ingresar a tu cuenta con tu misma contraseña'
							type='success'
							button={
								<Button
									type='button'
									onClickAction={() => {
										closeModal()
										onFinishChange()
									}}
									classname='f-button f-button--primary'
									text='Finalizar'
								/>
							}
						/>
					</div>
				)}
				<button
					className='f-exit-btn'
					onClick={async () => {
						step === 1 && (await delateChangeEmail())
						closeModal()
						step === 2 && onFinishChange()
					}}
				>
					X
				</button>
			</div>
		</LayoutModal>
	)
}

export default ChangeEmailContainer
