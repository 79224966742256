export const formatDate = (date, isShort = false) => {
	const monthNames = [
		'enero',
		'febrero',
		'marzo',
		'abril',
		'mayo',
		'junio',
		'julio',
		'agosto',
		'septiembre',
		'octubre',
		'noviembre',
		'diciembre',
	]

	const monthNamesShort = [
		'ene',
		'feb',
		'mar',
		'abr',
		'may',
		'jun',
		'jul',
		'ago',
		'sep',
		'oct',
		'nov',
		'dic',
	]

	const day = date.getDate()
	const monthIndex = date.getMonth()
	const year = date.getFullYear()

	return isShort
		? day + '/' + monthNamesShort[monthIndex] + '/' + year
		: day + ' ' + monthNames[monthIndex] + ' ' + year
}

export const calculateAge = bornDate => {
	const actualDate = new Date()
	const userBornDate = new Date(bornDate)
	let Age = actualDate.getFullYear() - userBornDate.getFullYear()
	const monthDifference = actualDate.getMonth() - userBornDate.getMonth()

	if (
		monthDifference < 0 ||
		(monthDifference === 0 && actualDate.getDate() < userBornDate.getDate())
	) {
		Age--
	}
	return Age
}
