import React from 'react'
import Icon from '../Icon/Icon'
import './alert.scss'

const Alert = props => (
	<div
		className={`f-alert-message-wrapper f-alert-message-wrapper--${props.alertType}`}
	>
		<div className='f-alert-message'>
			<Icon
				icon={`${
					props.alertType === 'info'
						? 'icon-exclamation-circle'
						: props.alertType === 'error' || props.alertType === 'error-leaflet'
						? 'icon-exclamation-triangle'
						: ''
				} white`}
				tagtype='span'
				size='2'
			/>
			<p className='f-p'>{props.description}</p>
		</div>
	</div>
)

export default Alert
