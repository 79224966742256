import React from 'react'
import './accountScoreHeader.scss'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import { formatNumber } from '../../../shared/state/utils/utilities-format-number'
import { formatDate } from '../../../shared/state/utils/utilities-date'

const AccountScoreHeader = props => {
	const { isBrowser } = useBrowserMode()
	const userPoints = props.actualScore && formatNumber(props.actualScore)
	const pointsToExpire = formatNumber(props.pointsToExpire || 0)
	const currentDate = new Date()
	const datePointsToExpire = new Date(
		props.datePointsToExpire || currentDate.toString()
	)

	const DAY_AS_MILLISECONDS = 86400000
	const diferentBetweenDates = Math.abs(datePointsToExpire - currentDate)
	const daysToExpire = Math.ceil(diferentBetweenDates / DAY_AS_MILLISECONDS)

	return (
		<>
			{isBrowser &&
				(props.locale === 'es-CO' ? (
					<div className='f-account-title-wrapper'>
						<h1 className='f-account-title'>MI CUENTA</h1>
						<div className='f-score-wrapper'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='19'
								viewBox='0 0 20 19'
							>
								<path
									className='a'
									d='M22,9.24l-7.19-.62L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21,12,17.27,18.18,21l-1.63-7.03ZM12,15.4,8.24,17.67l1-4.28L5.92,10.51l4.38-.38L12,6.1l1.71,4.04,4.38.38L14.77,13.4l1,4.28Z'
									transform='translate(-2 -2)'
								/>
							</svg>
							<h4 className='f-account-subtitle'>
								Tienes {userPoints} puntos disponibles
							</h4>
						</div>
						{pointsToExpire > 0 &&
							(datePointsToExpire.getMonth() >= currentDate.getMonth() ||
								datePointsToExpire.getYear() > currentDate.getYear()) && (
								<div className='f-score-wrapper'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='19'
										viewBox='0 0 20 19'
										className='mobile'
									>
										<path
											className='a'
											d='M22,9.24l-7.19-.62L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21,12,17.27,18.18,21l-1.63-7.03ZM12,15.4,8.24,17.67l1-4.28L5.92,10.51l4.38-.38L12,6.1l1.71,4.04,4.38.38L14.77,13.4l1,4.28Z'
											transform='translate(-2 -2)'
										/>
									</svg>
									<h4 className='f-account-subtitle separator desktop'>-</h4>
									<h4 className='f-account-subtitle'>
										{daysToExpire < 30
											? `En ${daysToExpire} ${
													daysToExpire === 1 ? 'día' : 'días'
											  } vencen ${pointsToExpire} puntos`
											: `Tus próximos puntos a vencer ${formatDate(
													datePointsToExpire,
													true
											  )}`}
									</h4>
								</div>
							)}
					</div>
				) : (
					<></>
				))}
		</>
	)
}

export default AccountScoreHeader
