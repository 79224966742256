import React, { useState } from 'react'
// import { FormControlLabel, Checkbox } from "@material-ui/core"
// import Icon from "../../../shared/components/atoms/Icon/Icon"
import RadioMarkCard from '../../../shared/components/molecules/radio-mark-card/radio-mark-card'
import * as constants from '../../../shared/state/constants/appConstants'
import './userRolesFormInfo.scss'

const UserRolesFormInfo = props => {
	const {
		ROL_COMPRADOR,
		ROL_USUARIO,
		ROL_CUIDADOR_FAMILIAR,
		ROL_CUIDADOR_PROFESIONAL,
	} = constants
	const [currentUserTypeId, setcurrentUserTypeId] = useState([
		props.rolesArray.filter(role => role.nombreRol === ROL_USUARIO.nombreRol)
			.length === 1,
		props.rolesArray.filter(role => role.nombreRol === ROL_COMPRADOR.nombreRol)
			.length === 1,
		props.rolesArray.filter(
			role => role.nombreRol === ROL_CUIDADOR_FAMILIAR.nombreRol
		).length === 1,
		props.rolesArray.filter(
			role => role.nombreRol === ROL_CUIDADOR_PROFESIONAL.nombreRol
		).length === 1,
	])

	return (
		<div className='f-cards-wrapper f-user-form'>
			<RadioMarkCard
				title={props.registerRolesInfo.soyUsuarioTitulo}
				description={props.registerRolesInfo.soyUsuarioDescripcion}
				image={props.registerRolesInfo.soyUsuarioImagen[0]}
				isActive={currentUserTypeId[0]}
				setActive={() => {
					setcurrentUserTypeId([
						!currentUserTypeId[0],
						currentUserTypeId[1],
						currentUserTypeId[2],
						currentUserTypeId[3],
					])
					props.setNewRole(currentUserTypeId[0], ROL_USUARIO)
				}}
			/>
			<RadioMarkCard
				title={props.registerRolesInfo.soyCompradorTitulo}
				description={props.registerRolesInfo.soyCompradorDescripcion}
				image={props.registerRolesInfo.soyCompradorImagen[0]}
				isActive={currentUserTypeId[1]}
				setActive={() => {
					setcurrentUserTypeId([
						currentUserTypeId[0],
						!currentUserTypeId[1],
						currentUserTypeId[2],
						currentUserTypeId[3],
					])
					props.setNewRole(currentUserTypeId[1], ROL_COMPRADOR)
				}}
			/>
			<RadioMarkCard
				title={props.registerRolesInfo.noSoyProfesionalTitulo}
				description={props.registerRolesInfo.noSoyProfesionalDescripcion}
				image={props.registerRolesInfo.soyCuidadorImagen[0]}
				isActive={currentUserTypeId[2]}
				setActive={() => {
					setcurrentUserTypeId([
						currentUserTypeId[0],
						currentUserTypeId[1],
						!currentUserTypeId[2],
						currentUserTypeId[3],
					])
					props.setNewRole(currentUserTypeId[2], ROL_CUIDADOR_FAMILIAR)
				}}
			/>
			<RadioMarkCard
				title={props.registerRolesInfo.soyProfesionalTitulo}
				description={props.registerRolesInfo.soyProfesionalDescripcion}
				image={props.registerRolesInfo.soyCuidadorImagen[0]}
				isActive={currentUserTypeId[3]}
				setActive={() => {
					setcurrentUserTypeId([
						currentUserTypeId[0],
						currentUserTypeId[1],
						currentUserTypeId[2],
						!currentUserTypeId[3],
					])
					props.setNewRole(currentUserTypeId[3], ROL_CUIDADOR_PROFESIONAL)
				}}
			/>
			{/* <FormControlLabel
                control={<Checkbox checked={(props.rolesArray.filter(role => role.nombreRol === ROL_USUARIO.nombreRol).length === 1)} onChange={props.setNewRole(ROL_USUARIO)} value={ROL_USUARIO} className="f-rol-check" />}
                label={
                    <>
                        <span className='f-icon-wrapper'><Icon icon="icon-check white" size="0-7" tagtype="i" /></span>
                        <h3 className="f-card-title">{props.registerRolesInfo.soyUsuarioTitulo}</h3>
                        <p className="f-card-subtitle">{props.registerRolesInfo.soyUsuarioDescripcion}</p>
                    </>
                }
                className={(props.rolesArray.filter(role => role.nombreRol === ROL_USUARIO.nombreRol).length === 1) ? 'f-rol-card f-rol-card-checked' : 'f-rol-card'}
                id="idSoyUsuario"
            />
            <FormControlLabel
                control={<Checkbox checked={(props.rolesArray.filter(role => role.nombreRol === ROL_COMPRADOR.nombreRol).length === 1)} onChange={props.setNewRole(ROL_COMPRADOR)} value={ROL_COMPRADOR} className="f-rol-check" />}
                label={
                    <>
                        <span className='f-icon-wrapper'><Icon icon="icon-check white" size="0-7" tagtype="i" /></span>
                        <h3 className="f-card-title">{props.registerRolesInfo.soyCompradorTitulo}</h3>
                        <p className="f-card-subtitle">{props.registerRolesInfo.soyCompradorDescripcion}</p>
                    </>
                }
                className={(props.rolesArray.filter(role => role.nombreRol === ROL_COMPRADOR.nombreRol).length === 1) ? 'f-rol-card f-rol-card-checked' : 'f-rol-card'}
                id="idSoyComprador"
            />
            <FormControlLabel
                control={<Checkbox checked={(props.rolesArray.filter(role => role.nombreRol === ROL_CUIDADOR_FAMILIAR.nombreRol).length === 1)} onChange={props.setNewRole(ROL_CUIDADOR_FAMILIAR)} value={ROL_CUIDADOR_FAMILIAR} className="f-rol-check" />}
                label={
                    <>
                        <span className='f-icon-wrapper'><Icon icon="icon-check white" size="0-7" tagtype="i" /></span>
                        <h3 className="f-card-title">{props.registerRolesInfo.noSoyProfesionalTitulo}</h3>
                        <p className="f-card-subtitle">{props.registerRolesInfo.noSoyProfesionalDescripcion}</p>
                    </>
                }
                className={(props.rolesArray.filter(role => role.nombreRol === ROL_CUIDADOR_FAMILIAR.nombreRol).length === 1) ? 'f-rol-card f-rol-card-checked' : 'f-rol-card'}
                id="idSoyCuidador"
            />
            <FormControlLabel
                control={<Checkbox checked={(props.rolesArray.filter(role => role.nombreRol === ROL_CUIDADOR_PROFESIONAL.nombreRol).length === 1)} onChange={props.setNewRole(ROL_CUIDADOR_PROFESIONAL)} value={ROL_CUIDADOR_PROFESIONAL} className="f-rol-check" />}
                label={
                    <>
                        <span className='f-icon-wrapper'><Icon icon="icon-check white" size="0-7" tagtype="i" /></span>
                        <h3 className="f-card-title">{props.registerRolesInfo.soyProfesionalTitulo}</h3>
                        <p className="f-card-subtitle">{props.registerRolesInfo.soyProfesionalDescripcion}</p>
                    </>
                }
                className={(props.rolesArray.filter(role => role.nombreRol === ROL_CUIDADOR_PROFESIONAL.nombreRol).length === 1) ? 'f-rol-card f-rol-card-checked' : 'f-rol-card'}
                id="idInstitution"
            /> */}
		</div>
	)
}

export default UserRolesFormInfo
