export const months = [
	{
		month: 'Enero',
		number: 1,
	},
	{
		month: 'Febrero',
		number: 2,
	},
	{
		month: 'Marzo',
		number: 3,
	},
	{
		month: 'Abril',
		number: 4,
	},
	{
		month: 'Mayo',
		number: 5,
	},
	{
		month: 'Junio',
		number: 6,
	},
	{
		month: 'Julio',
		number: 7,
	},
	{
		month: 'Agosto',
		number: 8,
	},
	{
		month: 'Septiembre',
		number: 9,
	},
	{
		month: 'Octubre',
		number: 10,
	},
	{
		month: 'Noviembre',
		number: 11,
	},
	{
		month: 'Diciembre',
		number: 12,
	},
]

export var datesArray = []

export const dates = month => {
	let monthDate
	switch (month) {
		case 1:
		case 3:
		case 5:
		case 7:
		case 8:
		case 10:
		case 12:
			monthDate = 31
			break
		case 4:
		case 6:
		case 9:
		case 11:
			monthDate = 30
			break
		case 2:
			monthDate = 29
			break
		default:
			monthDate = 30
			break
	}
	let i

	datesArray = []

	for (i = 1; i < monthDate + 1; i++) {
		datesArray.push(i)
	}
}

export const yearsArray = []

export const years = (startY, endY) => {
	let i
	if (yearsArray.length === 0) {
		for (i = endY; i > startY - 1; i--) {
			yearsArray.push(i)
		}
	}
}
