import React from 'react'
import './radio.scss'

const Radio = props => (
	<div className='f-radio-container'>
		<span
			onClick={props.setActive}
			className={`f-radio ${props.isActive ? 'f-radio--active' : ''}`}
		/>
		{props.text ? (
			<label className={`f-label ${props.isActive ? 'f-label--active' : ''}`}>
				{props.text}
			</label>
		) : null}
	</div>
)

export default Radio
