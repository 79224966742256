import React from 'react'
import { useForm } from 'react-hook-form'
import InstitutionGeneralInfoForm from '../../molecules/institutionGeneralInfoForm/institutionGeneralInfoForm'
import InstitutionAdminInfoForm from '../../molecules/institutionAdminInfoForm/institutionAdminInfoForm'
import ChangePassContainer from '../changePassContainer/changePassContainer'
import ButtonLoading from '../../molecules/buttonLoading/buttonLoading'
import RegisterService from '../../../shared/services/register'
import Button from '../../../shared/components/atoms/Button/Button'
import { DOCUMENT_TYPE_NIT } from '../../../shared/state/constants/appConstants'
import {
	localStorage,
	sessionStorage,
} from '../../../shared/state/utils/storage'
import SessionService from '../../../shared/services/session'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import AdviserService from '../../../shared/services/adviser'
import ProfileService from '../../../shared/services/profile'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import {
	FormControl,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
} from '@material-ui/core'
import './institutionAccountContainer.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const InstitutionAccountContainer = props => {
	const { passwordModal, setPasswordModal } = props

	// const [passwordModal, setPasswordModal] = React.useState(false);
	const [isLoaderVisible, setLoaderVisible] = React.useState(false)
	const { locale } = useLocaleMode()
	const [citiesArray, setCities] = React.useState([])
	const institutionInfo = Object.assign(
		{},
		props.storageInfo && props.storageInfo
	)
	const isCO = process.env.LOCALE === 'es-CO'
	const isAR = process.env.LOCALE === 'es-AR'
	const [countryPosition, setCountryPosition] = React.useState(
		institutionInfo.mainCountry !== null &&
			institutionInfo.mainCountry !== undefined
			? props.locationInfo.findIndex(
					node =>
						Number(node.codigoDelPais) === Number(institutionInfo.mainCountry)
			  )
			: props.locationInfo.findIndex(node =>
					locale.includes(node.codigoDeDosLetras)
			  )
	)
	const prefijo = props.locationInfo[countryPosition].prefijoTelefonico
	institutionInfo.cellPhone = parseInt(
		institutionInfo.cellPhone?.toString().replace(prefijo, '')
	)
	const [institutionInitialInfo, setInstitutionInitialInfo] = React.useState({
		...props.storageInfo,
		cellPhone: parseInt(
			props.storageInfo.cellPhone?.toString().replace(prefijo, '')
		),
		adminGender: props.storageInfo.institutionResponsible?.gender || '',
		adminName: props.storageInfo.institutionResponsible?.name || '',
		adminLastName: props.storageInfo.institutionResponsible?.lastName || '',
		// adminPhone: changeCellPhoneData(institutionInfo.adminUserDto.cellPhone) || '',
		// adminEmail: props.storageInfo.institutionResponsible?.email || '',
		adminPosition: props.storageInfo.institutionResponsible?.position || '',
	})
	// const institutionInitialInfo = Object.assign({}, props.storageInfo && props.storageInfo);
	const [notiTena, setNotiTena] = React.useState(
		institutionInfo.shippingMainAddres
	)
	const [previouslyPurchased, setPreviouslyPurchased] = React.useState(
		institutionInfo.isCustomer === null ? '' : institutionInfo.isCustomer
	)
	const [isNotiTenaVisible] = React.useState(() => {
		switch (locale) {
			case 'es-CO':
			case 'es-PE':
			case 'es-PR':
			case 'es-DO':
				return true
			// case "es-AR":
			//   return false;
			// case "es-BO":
			//   return false;
			default:
				return false
		}
	})
	const [contactMethod, setContactMethod] = React.useState({
		smsContact: institutionInfo.smsContact,
		emailContact: institutionInfo.emailContact,
		whatsappContact: institutionInfo.whatsappContact,
	})
	const [isDataActive, setActive] = React.useState({
		adminEmail: false,
		adminPhone: false,
		cellPhone: false,
		nit: false,
	})

	const {
		register,
		control,
		handleSubmit,
		errors,
		setValue,
		getValues,
		formState,
		triggerValidation,
		clearError,
	} = useForm({
		defaultValues: {
			name: institutionInfo.name || '',
			nit: institutionInfo.numberId || '',
			headquarters: institutionInfo.institutionResponsible?.headquarters || '',
			cellPhone: institutionInfo.cellPhone || '',
			email: institutionInfo.email || '',
			country:
				(institutionInfo.mainCountry && institutionInfo.mainCountry) ||
				props.locationInfo[countryPosition].codigoDelPais,
			countryState: institutionInfo.mainCountryState || '',
			city: institutionInfo.mainCity || '',
			address: institutionInfo.mainAddress || '',
			adminName: institutionInfo.institutionResponsible?.name || '',
			adminLastName: institutionInfo.institutionResponsible?.lastName || '',
			adminGender: institutionInfo.institutionResponsible?.gender || '',
			adminPosition: institutionInfo.institutionResponsible?.position || '',
			kindOfStreet: institutionInfo.address ?  institutionInfo.address.tipoCalle : '',
			numberStreet: institutionInfo.address ?  institutionInfo.address.numeroTipo : '',
			firstNumber: institutionInfo.address ?  institutionInfo.address.numero.slice(2)  : '',
			secondaryNumber: institutionInfo.address ?  institutionInfo.address.numeroSecundario.slice(2) : '',
			neighborhood: institutionInfo.addressDetail ? institutionInfo.addressDetail.barrio : '',
			block: institutionInfo.addressDetail ? institutionInfo.addressDetail.detalle : '',
			aditionalReferences: institutionInfo.addressDetail ? institutionInfo.addressDetail.referencia !== 'undefined' ? institutionInfo.addressDetail.referencia : '': '',
			caregivers: institutionInfo.institutionResponsible?.caregivers || '',
			currentUsers: institutionInfo.institutionResponsible?.currentUsers || '',
			usersWithIncontinence:
				institutionInfo.institutionResponsible?.usersWithIncontinence || '',
			organizationType:
				institutionInfo.institutionResponsible?.organizationType,

			/* Campos Argentina */
			addressARG: institutionInfo.address ?  institutionInfo.address.tipoCalle : '',
			height: institutionInfo.address ?  institutionInfo.address.numeroTipo : '',
			floor: institutionInfo.address ?  institutionInfo.address.numero === "null" ? 'Sin piso' : institutionInfo.address.numero : '',
			department: institutionInfo.address ?  institutionInfo.address.numeroSecundario === "null" ? 'Sin departamento' : institutionInfo.address.numeroSecundario : '',
			zipCode: institutionInfo.addressDetail ? institutionInfo.addressDetail.barrio : '',
			aditionalInformation: institutionInfo.addressDetail ? institutionInfo.addressDetail.detalle === "null" ? '' : institutionInfo.addressDetail.detalle : '',
		},
	})
	const formValues = getValues()

	React.useEffect(() => {
		let currentCountryState
		let currentCity
		institutionInfo.mainCountryState &&
			props.locationInfo[countryPosition].departamentos.map(countryState => {
				if (institutionInfo.mainCountryState === countryState.codigo) {
					getCities('countryState', countryState)
					currentCountryState = countryState
				}
				return countryState
			})

		props.locationInfo[countryPosition].codigoDelPais !== 12 &&
		props.locationInfo[countryPosition].ciudades
			? institutionInfo.mainCity &&
			  props.locationInfo[countryPosition].ciudades.map(city => {
					if (institutionInfo.mainCity === city.codigo) {
						currentCity = city
					}
					return city
			  })
			: institutionInfo.mainCity &&
			  props.locationInfo[countryPosition].departamentos.map(dep => {
					if (institutionInfo.mainCity === dep.codigo) {
						currentCountryState = dep
					}
					return dep
			  })
		locale === 'es-PR'
			? setValues({
					...values,
					city: currentCountryState,
					countryState: currentCountryState,
			  })
			: setValues({
					...values,
					city: currentCity,
					countryState: currentCountryState,
			  })
		// setHelpers();
	}, [])
	const [values, setValues] = React.useState({
		country:
			(institutionInfo.mainCountry && institutionInfo.mainCountry) ||
			props.locationInfo[countryPosition].codigoDelPais,
		countryState: {},
		city: {},
		inputLength: 0,
		adminGender: institutionInfo.institutionResponsible?.gender || '',
		adminPosition: institutionInfo.institutionResponsible?.position || '',
		organizationType:
			institutionInfo.institutionResponsible?.organizationType || '',
		isRegisterDataValid: true,
		isNewAddress: institutionInfo.nueva_direccion || false,
		kindOfStreet: institutionInfo.address ?  institutionInfo.address.tipoCalle : '',
		numberStreet: institutionInfo.address ?  institutionInfo.address.numeroTipo : '',
		firstNumber: institutionInfo.address ?  institutionInfo.address.numero.slice(2) : '',
		secondaryNumber: institutionInfo.address ?  institutionInfo.address.numeroSecundario.slice(2) : '',
		neighborhood: institutionInfo.addressDetail ? institutionInfo.addressDetail.barrio : '',
		block: institutionInfo.addressDetail ? institutionInfo.addressDetail.detalle : '',
		aditionalReferences: institutionInfo.addressDetail ? institutionInfo.addressDetail.referencia !== 'undefined' ? institutionInfo.addressDetail.referencia : '': '',
		address: institutionInfo.mainAddress || '',
		numberId: institutionInfo.numberId || '',
		mainAddressDetail: institutionInfo.mainAddressDetail || '',

		/* Campos Argentina */
		addressARG: institutionInfo.address ?  institutionInfo.address.tipoCalle : '',
		height: institutionInfo.address ?  institutionInfo.address.numeroTipo : '',
		floor: institutionInfo.address ?  institutionInfo.address.numero === "null" ? 'Sin piso' : institutionInfo.address.numero : '',
		department: institutionInfo.address ?  institutionInfo.address.numeroSecundario === "null" ? 'Sin departamento' : institutionInfo.address.numeroSecundario : '',
		zipCode: institutionInfo.addressDetail ? institutionInfo.addressDetail.barrio : '',
		aditionalInformation: institutionInfo.addressDetail ? institutionInfo.addressDetail.detalle === "null" ? '' : institutionInfo.addressDetail.detalle : '',
	})

	const handleClick = event => {
		const { name: nameContact, checked: valueContact } = event.target
		const actualContactMethod = {
			...contactMethod,
			[nameContact]: valueContact,
		}

		if (
			actualContactMethod.emailContact !== false ||
			actualContactMethod.smsContact !== false ||
			actualContactMethod.whatsappContact !== false
		) {
			setContactMethod(actualContactMethod)

			if (
				institutionInitialInfo.emailContact !==
					actualContactMethod.emailContact ||
				institutionInitialInfo.smsContact !== actualContactMethod.smsContact ||
				institutionInitialInfo.whatsappContact !==
					actualContactMethod.whatsappContact
			) {
				props.setFormChange(true)
			} else {
				props.setFormChange(false)
			}
		}
	}

	const handleValidButtonStatus = () => {
		if (props.hasFormChanged) {
			if (values.isRegisterDataValid) {
				return true
			} else {
				return false
			}
		}
	}

	const onSubmit = data => {

		const floorPayload = data.floor === "Sin piso"? null : data.floor;
		const departmentPayload = data.department === "Sin departamento"? null : data.department;
		const aditionalInformationPayload = data.aditionalInformation === "" ? null : data.aditionalInformation;

		const prefijo = props.locationInfo[countryPosition].prefijoTelefonico
		const { emailContact, smsContact, whatsappContact } = contactMethod
		const mainAddressDetail= isCO ? `${data.neighborhood}?${data.block}?${data.aditionalReferences}` 
		: isAR ? `${data.zipCode}?${aditionalInformationPayload}` : data.mainAddressDetail
		const updatedInfo = {
			email: institutionInfo.email,
			roles: institutionInfo.roles,
			name: data.name,
			numberId: data.nit?.toString(),
			typeId: DOCUMENT_TYPE_NIT.text,
			cellPhone: prefijo + data.cellPhone,
			emailContact,
			smsContact,
			whatsappContact,
			shippingMainAddres: notiTena,
			mainAddress:isCO ? `${data.kindOfStreet}?${data.numberStreet}?# ${data.firstNumber}?- ${data.secondaryNumber}` 
			: isAR ? `${data.addressARG}?${data.height}?${floorPayload}?${departmentPayload}` : data.address,
			mainCity:
				values.country !== 12
					? values.city
						? values.city.codigo
						: null
					: values.countryState
					? values.countryState.codigo
					: null,
			mainCountry: values.country,
			mainCountryState:
				values.country !== 12
					? values.countryState
						? values.countryState.codigo
						: null
					: null,
			institutionResponsible: {
				name: data.adminName,
				lastName: data.adminLastName,
				gender: values.adminGender,
				position: values.adminPosition,
				headquarters: parseInt(data.headquarters, 10),
			},
		}
		setLoaderVisible(true)
		updatedInfo.mainAddressDetail = mainAddressDetail
		let ServiceUpdate = ProfileService
		if (isAdviser(SessionService.getIdTokenData())) {
			updatedInfo.isCustomer = previouslyPurchased
			ServiceUpdate = AdviserService
		}

		ServiceUpdate.updateProfile(
			updatedInfo,
			res => {
				setLoaderVisible(false)
				if (res.data.status === 200) {
					props.setFormSubmit(true)
					const updatedData = res.data.data
					props.actualStorage === 0
						? localStorage.setItem('userData', updatedData)
						: sessionStorage.setItem('userData', updatedData)

					setInstitutionInitialInfo({
						...updatedData,
						cellPhone: parseInt(
							updatedData.cellPhone?.toString()?.replace(prefijo, '')
						),
						adminGender: updatedData.institutionResponsible?.gender,
						adminName: updatedData.institutionResponsible?.name,
						adminLastName: updatedData.institutionResponsible?.lastName,
						adminPosition: updatedData.institutionResponsible?.position,
					})
				} else if (res.data.status === 400 && res.data.errorCode === 'UEM050') {
					alert('Ha ocurrido un error, intentelo de nuevo más tarde')
				}
				setLoaderVisible(false)
			},
			error => {
				setLoaderVisible(false)
				console.log(error)
			}
		)
	}
	const checkCellPhoneData = event => {
		const prefijo = props.locationInfo[countryPosition].prefijoTelefonico
		const phoneData = {
			cellPhone: prefijo + event.target.value,
		}
		if (
			(locale === 'es-PE'
				? event.target.value.length === 9
				: locale === 'es-BO'
				? event.target.value.length === 8
				: event.target.value.length === 10) &&
			Number(event.target.value) !== institutionInfo.cellPhone
		) {
			RegisterService.checkPhone(phoneData).then(res => {
				if (res.status === 400 && res.errorCode === 'DEM011') {
					setActive({ ...isDataActive, [name]: true })
				}
			})
		}
		// (formValues.cellPhone === formValues.adminPhone ||
		//     isDataActive.adminPhone || isDataActive.cellPhone) ?
		//     setValues({ ...values, isRegisterDataValid: false }) : setValues({ ...values, isRegisterDataValid: true });
	}
	const checkInstitutionId = event => {
		const payload = {
			numberId: event.target.value.toString(),
		}
		if (Number(event.target.value) !== Number(institutionInfo.numberId)) {
			RegisterService.checkInstitutionId(payload)
				.then(resp => {
					if (resp.status === 400 && resp.errorCode === 'DEM050') {
						setActive({ ...isDataActive, nit: true })
					} else if (resp.status === 200) {
						setActive({ ...isDataActive, nit: false })
					}
				})
				.catch(err => console.log(err))
		}
	}
	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		triggerValidation({ name })
		if (!Object.is(institutionInitialInfo[name]?.toString(), value)) {
			props.setFormChange(true)
		} else {
			props.setFormChange(false)
		}
	}
	const handleSelectStreet = event => {
		const name = event.target.name
		const value = event.target.value
		const typedValue = name === 'country' ? Number(value) : value
		if (!Object.is(institutionInitialInfo[name], typedValue)) {
			setValue(name, typedValue)
			setValues({ ...values, [name]: typedValue })
			;(name === 'state' || name === 'secondState') &&
				getCities(name, typedValue)
			props.setFormChange(true)
			clearError('documentType')
			clearError('kindOfStreet')
		} else {
			props.setFormChange(false)
		}
	}
	const handleSelection = () => event => {
		const name = event.target.name
		const value = event.target.value
		const typedValue = name === 'country' ? Number(value) : value
		if (name === 'country') {
			const currentCountry = props.locationInfo.findIndex(
				node => Number(node.codigoDelPais) === typedValue
			)
			setCountryPosition(currentCountry)
			getCities(name, typedValue)
		}
		if (!Object.is(institutionInitialInfo[name], value)) {
			setValue(name, typedValue)
			setValues({ ...values, [name]: typedValue })
			name === 'countryState' && getCities(name, typedValue)
			props.setFormChange(true)
		} else {
			setValues({ ...values, [name]: typedValue })
			props.setFormChange(false)
		}
	}
	const getCities = (prop, stateValue) => {
		setValue(prop, stateValue)
		const newArray =
			locale === 'es-PR'
				? null
				: props.locationInfo[countryPosition].ciudades.filter(
						city => city.codigoDepartamento === stateValue.codigo
				  )
		setCities(newArray)
	}
	return (
		<div className='f-institution-account-container'>
			<form onSubmit={handleSubmit(onSubmit)} className='f-user-form-wrapper'>
				<section className='f-user-section f-user-section-institution'>
					<div className='f-title-wrapper f-title-wrapper-house'>
						<h3 className='f-section-title f-section-title-house'>
							INFORMACIÓN GENERAL
						</h3>
						{/* <p className='f-password-cta' onClick={() => setPasswordModal(true)}>CAMBIAR MI CONTRASEÑA</p> */}
					</div>
					<InstitutionGeneralInfoForm
						register={register}
						control={control}
						handleChange={handleChange}
						handleSelection={handleSelection}
						handleSelectStreet={handleSelectStreet}
						checkInstitutionId={checkInstitutionId}
						checkCellPhoneData={checkCellPhoneData}
						errors={errors}
						institutionFormInfo={props.userFormInfo.formularioInstitucion}
						formValues={formValues}
						formState={formState}
						citiesArray={citiesArray}
						values={values}
						setValue={setValue}
						setValues={setValues}
						setActive={setActive}
						isDataActive={isDataActive}
						isMyAccount={true}
						locationInfo={props.locationInfo}
						countryPosition={countryPosition}
						country={props.locationInfo[countryPosition]}
						clearError={clearError}
					/>
				</section>
				<section className='f-user-section f-user-section-institution'>
					<div className='f-title-wrapper f-title-wrapper-single'>
						<h3 className='f-section-title f-section-title-single'>
							RESPONSABLE DE LA INSTITUCIÓN
						</h3>
					</div>
					<InstitutionAdminInfoForm
						register={register}
						handleChange={handleChange}
						handleSelection={handleSelection}
						errors={errors}
						institutionFormInfo={props.userFormInfo.formularioInstitucion}
						formValues={formValues}
						formState={formState}
						values={values}
						isDataActive={isDataActive}
						isMyAccount={true}
					/>
				</section>

				{isNotiTenaVisible ? (
					<section className='f-user-section f-user-section-institution f-user-section-space'>
						<div className='f-samples-wrapper'>
							<div className='f-title-wrapper'>
								<p className='f-samples-title'>
									¿Quieres recibir en tu casa los beneficios que tenemos para
									ti?
								</p>
							</div>
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='gender'
									name='gender1'
									className={'f-samples-radio'}
									value={notiTena?.toString()}
									onChange={event => {
										const realValue = event.target.value === 'true'
										props.setFormChange(
											institutionInitialInfo.shippingMainAddres !== realValue
										)
										setNotiTena(realValue)
									}}
								>
									<FormControlLabel
										value='true'
										control={<Radio />}
										label='SI'
										labelPlacement='start'
									/>
									<FormControlLabel
										value='false'
										control={<Radio />}
										label='NO'
										labelPlacement='start'
									/>
								</RadioGroup>
							</FormControl>
						</div>
					</section>
				) : null}

				{(localStorage.getItem('tokenData') ||
					sessionStorage.getItem('tokenData')) &&
				isAdviser(SessionService.getIdTokenData()) ? (
					<section className='f-user-section f-user-section-institution f-user-section-space'>
						<div className='f-samples-wrapper'>
							<div className='f-title-wrapper'>
								<p className='f-samples-title'>
									¿Has comprado productos TENA en nuestro e-commerce?
								</p>
							</div>
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='previouslyPurchased'
									name='previouslyPurchased'
									className={'f-samples-radio'}
									value={previouslyPurchased?.toString()}
									onChange={event => {
										const realValue = event.target.value === 'true'
										props.setFormChange(true)
										setPreviouslyPurchased(realValue)
									}}
								>
									<FormControlLabel
										value='true'
										control={<Radio />}
										label='SI'
										labelPlacement='start'
									/>
									<FormControlLabel
										value='false'
										control={<Radio />}
										label='NO'
										labelPlacement='start'
									/>
								</RadioGroup>
							</FormControl>
						</div>
					</section>
				) : null}

				<section className='f-user-section f-user-section-institution f-user-section-space'>
					<div className='f-activation-wrapper'>
						<div className='f-activation-title-wrapper'>
							<p className='f-activation-title'>
								¿Por qué medio deseas que te contacten?
							</p>
						</div>
						<FormControl component='fieldset'>
							<div className='f-activation-controls'>
								<FormControlLabel
									control={
										<Checkbox
											checked={contactMethod.emailContact}
											onClick={handleClick}
											inputProps={{ name: 'emailContact' }}
											className='f-rol-check'
										/>
									}
									label='Mi correo'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={contactMethod.smsContact}
											onClick={handleClick}
											inputProps={{ name: 'smsContact' }}
											className='f-rol-check'
										/>
									}
									label='Mi celular(SMS)'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={contactMethod.whatsappContact}
											onClick={handleClick}
											inputProps={{ name: 'whatsappContact' }}
											className='f-rol-check'
										/>
									}
									label='Mi celular(WhatsApp)'
								/>
							</div>
						</FormControl>
					</div>
				</section>
				<ButtonLoading
					isEnabled={
						!handleValidButtonStatus()
						// !(props.hasFormChanged && formState.touched.length > values.inputLength && values.isRegisterDataValid)
					}
					button={
						<Button
							id='f-login-submit-btn'
							type='input'
							classname='f-button f-button--primary'
							text={convertText('ACTUALIZAR DATOS')}
						/>
					}
					isShowingLoader={isLoaderVisible}
					bgClass='primary'
				/>
			</form>
			{passwordModal && (
				<ChangePassContainer
					type={0}
					closeModal={() => setPasswordModal(false)}
					actualStorage={props.actualStorage}
					formValues={formValues}
				/>
			)}
		</div>
	)
}
export default InstitutionAccountContainer
