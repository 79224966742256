import React, { useState, useEffect } from 'react'
import './input-material-with-check.scss'
import Alert from '../alert/alert'

const InputMaterialWithCheck = props => {

	const {
		label,
		value,
		name,
		errors,
		register,
		change,
		autoComplete = false,
		classes = "",
		id,
		text,
		disabled = false,
		type = "text",
		blurEvent,
		onClick,
		showMessageError = true,
		setCheckRequired,
		setValue,
		whatFormIs = "registro",
		formValue,
		clearError
	} = props

	const [valueCheck, setValueCheck] = useState(false)

	const labelText = "Sin " + text;

	useEffect(() => {
		if (whatFormIs==="muestras-gratis" && value) {
			if (value === labelText) {
				document.getElementById('checkInt'+id).checked = true;
				setValueCheck(true)
			}
			else {
				document.getElementById('checkInt'+id).checked = false;
				setValueCheck(false)
			}
		}

		if (whatFormIs==="actualizacion" && formValue && formValue === labelText) {
			document.getElementById('checkInt'+id).checked = true;
			setValueCheck(true)
		}
	}, [value, formValue])
	


	const handleCheck = (e) => {
		const InputTextId = document.getElementById(id);
		setValueCheck(!valueCheck)
		
		if (whatFormIs==="actualizacion"){
			change(e);
		}

		if(whatFormIs !== "muestras-gratis"){
			setCheckRequired(!valueCheck)
		}
		
		if (valueCheck) {
			if(whatFormIs === "muestras-gratis"){
				setValue("")
			}
			else {
				InputTextId.value = "";
			}
		}
		else {
			if(whatFormIs === "muestras-gratis"){
				setValue(labelText)
			}
			else {
				InputTextId.value = labelText;
			}
		}

		if (clearError && whatFormIs !== "muestras-gratis") {
			clearError(name)
		}
	}
	
	return (
		<div
			className={
				`f-input-material ${classes} ` + (disabled || valueCheck ? 'f-disabled-input' : '')
			}
		>
			<label
				className={`${errors[name] && showMessageError
					? 'pure-material-textfield-outlined pure-material-textfield-outlined--error'
					: 'pure-material-textfield-outlined'
					}`}
			>
				<input
					id={id}
					onClick={onClick}
					onBlur={blurEvent}
					autoComplete={autoComplete ? 'on' : 'off'}
					onChange={change}
					ref={register}
					placeholder=' '
					name={name}
					value={value}
					type={type}
					className='f-input-material-input-container'
				/>
				<span>{label}</span>
				<div className='f-input-material-checkbox-container'>
					<input type='checkbox' id={'checkInt'+id} onChange={(e)=>{handleCheck(e)}} />
					<label htmlFor={"checkInt"+id}>{labelText}</label>
				</div>
			</label>

			{errors[name] && showMessageError && (
				<Alert alertType='error' description={errors[name].message} />
			)}
		</div>
	)
}

export default InputMaterialWithCheck
