import React from 'react'
import './radio-mark-card.scss'
import Radio from '../../../components/atoms/radio/radio'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'

const RadioMarkCard = props => {
	const { description } = props
	const { step=99 }= props
	const dataLayerRegistro = (step,option) => {
		let location=''
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Registro',
		category: step,
		action: 'click',
		label: option,
		location: location, 
		})
	}
	return (
		<div
			onClick={()=>{				
				switch(step){
					case 1:
						dataLayerRegistro('registro_1',props.title)
						break;
					case 2:
						dataLayerRegistro('registro_2',props.title)
						break;
				}
				props.setActive()
			}}
			className={`f-mark-card ${props.isActive ? 'f-mark-card--active' : ''} ${
				props.isReadOnly ? 'f-mark-card--disabled' : ''
			}`}
		>
			<div className='f-mark-card-header'>
				<div className='f-radio-wrapper'>
					<Radio setActive={props.setActive} isActive={props.isActive} />
					{/* <span onClick={props.setActive} className={`f-radio ${props.isActive ? 'f-radio--active' : ''}`} /> */}
				</div>
			</div>
			<div className='f-mark-card-body'>
				<div className='f-mark-card-body-title'>
					<img
						src={props.image.file.url}
						alt={props.image.description}
						className='f-image'
					/>
					<p>{props.title}</p>
				</div>
				<div className='f-mark-card-body-description'>
					{typeof description === 'string'
						? description
						: documentToReactComponents(props.description.json)}
				</div>
			</div>
		</div>
	)
}

export default RadioMarkCard
