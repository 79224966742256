import React from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import './tabProducts.scss'

const TabProducts = props => {
	const newSlug = props.slug.substring(props.slug.lastIndexOf('/') + 1)
	const { locale } = useLocaleMode()
	const { homeLink } = useHomeLinkMode()
	const nodeContainer = React.useRef()
	const { isBrowser } = useBrowserMode()
	const optionIsInMenu = props.tabs?.opcionesMenu?.filter(option =>
		option?.slug.includes(newSlug)
	)[0]
	const optionDefault = () => {
		const optionTemp = props.tabs?.opcionesMenu[0]
		if (optionTemp) {
			const newSlugTab = optionTemp.slug
				.substring(optionTemp.slug.lastIndexOf('/') + 1)
				.split('#')
			return newSlugTab[1] ? newSlugTab[1] : newSlugTab[0]
		} else {
			return 'productos'
		}
	}

	React.useEffect(() => {
		// if (props.slug === "productos" || props.slug === "muestra-gratis" || props.slug === "pide-tu-muestra" || props.slug === "programa") {
		//   nodeContainer.current.scrollLeft = 0;
		// } else {
		const itemSelect = window.location.pathname

		let index = props.tabs.opcionesMenu.findIndex(x => {
			if (x.slug.charAt(0) !== '/') {
				x.slug = '/' + x.slug
			}
			return x.slug === itemSelect
		})
		if (props.slug === 'acumula-puntos') {
			index = 1
		}
		let scrollY = 0
		for (let i = 0; i < index; i++) {
			scrollY += nodeContainer.current.children[i].clientWidth
		}
		nodeContainer.current.scrollLeft = scrollY
		// }
	})
	const userInfo=dataLayerUserInfo()
	let loc = ''
	let url = ''
	if (typeof window !== 'undefined') {
		loc = window.location.href
		url = window.location.pathname.split('/')
	}
	const dataLaterLineasP = (linea) => {		
		
		pushDataLayerEvent({event: 'Interacciones - Productos',
		category: 'productos',
		action: 'click',
		label:linea,
		sub_category:'Líneas de productos',
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: 'menu_click',
			action_type: 'Link',
			link_text: `Productos > Líneas de productos > ${linea}`,
			index: `1.2.${position}`,
			menu_name: 'menu lineas de productos'
		})
	}
	
	const dataLaterBeneficios = (lab, index) => {		
		
		pushDataLayerEvent({event: 'Interacciones - Beneficios',
		category: 'beneficios',
		action: 'click',
		label:lab,
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: "menu_click",
			action_type: "Link",
			link_text: "Beneficios > " + lab,
			index: index+1,
			menu_name: "menu beneficios"
		})
	}

	const dataLaterDatosP = (categoria,lab, index=0) => {
		pushDataLayerEvent({event: 'Interacciones - Mi Cuenta',
		category: categoria,
		action: 'click',
		label:lab,
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: "menu_click",
			action_type: "Link",
			link_text: "Datos Personales > " + lab,
			index: index+1,
			menu_name: "menu Datos Personales"
		})
	}
	
	return (
		<>
			{isBrowser &&
				(locale && (
					<aside
						className={`f-tabs-products ${
							props.title ? 'f-tabs-products--title' : ''
						} f-${optionIsInMenu ? newSlug : optionDefault()}`}
						style={{ borderColor: optionIsInMenu?.color || '' }}
					>
						{props.title && (
							<h3 className='f-tabs-products__title'>{props.title}</h3>
						)}

						<div
							ref={nodeContainer}
							className={`f-tabs-container ${
								props.title ? 'f-tabs-container--no-center' : ''
							}`}
						>
							{isBrowser &&
								props.tabs.opcionesMenu.map((tab, index) => {
									const { nombre, slug, color } = tab
									const newSlugTab = slug.substring(slug.lastIndexOf('/') + 1)
									const newSlugHash = newSlugTab.split('#')
									const realSlug =
										process.env.LOCALE === 'es-CO' &&
										(slug === '/productos/recomendador/colombia/' ||
											slug === '/productos/recomendador/colombia')
											? 'recomendador'
											: newSlugHash[1]
											? newSlugHash[1]
											: newSlugHash[0]
									return (
										<React.Fragment key={index}>
											<a
												onClick={()=>{
													// eslint-disable-next-line no-unused-expressions
													props.title==='LINEAS DE PRODUCTOS'? dataLaterLineasP(nombre, index+1):undefined
													// eslint-disable-next-line no-unused-expressions
													url[1]==='beneficios' ? dataLaterBeneficios(nombre, index):undefined
													// eslint-disable-next-line no-unused-expressions
													if(url[1]==='mi-cuenta'){ 
														switch(nombre){
															case 'DATOS PERSONALES':
																dataLaterDatosP('datos personales','Datos personales', index)
																break;
															case 'MIS PEDIDOS':
																dataLaterDatosP('mis pedidos','Mis pedidos', index)
																break;
															case 'MIS REDENCIONES':
																dataLaterDatosP('mis redenciones','Ver catálogo de redenciones', index)
																break;
															case 'MIS PUNTOS':
																dataLaterDatosP('mis puntos','Mis puntos', index)
																break;
															default:
																console.log('err');
														}
													
													}
												}}
												href={
													realSlug === 'recomendador' ||
													props.absoluteRedirection
														? (homeLink + slug).replace(
																'//productos',
																'/productos'
														  )
														: slug
												}
												style={{
													backgroundColor:
														color && newSlug === realSlug ? color : '',
													color: color && newSlug === realSlug ? 'white' : '',
												}}
												className={
													`f-tab f-tab-${realSlug}` +
													(window.location.hash !== ''
														? window.location.hash.substr(1) === realSlug
															? ` f-tab-active-${realSlug}`
															: ''
														: newSlug === realSlug
														? ` f-tab-active-${newSlug}`
														: !optionIsInMenu &&
														  realSlug !== 'recomendador' &&
														  index === 0
														? ` f-tab-active-${optionDefault()}`
														: props.slug.includes('redime-puntos') &&
														  realSlug === 'bonos-para-comprar-tena'
														? ' f-tab-active-bonos-para-comprar-tena'
														: '')
												}
											>
												{nombre}
											</a>
										</React.Fragment>
									)
								})}
						</div>
					</aside>
				))}
		</>
	)
}

export default TabProducts
