import React from 'react'
import Spacer from '../../../shared/components/atoms/spacer/spacer'
import './banner-title.scss'

const BannerTitle = ({ title, description, paddingLeft }) => {
	const style = { paddingLeft }

	return (
		<div className='f-banner-intern-title' style={style}>
			<Spacer />
			<h1 className='f-banner-intern-title-title f-h1'>{title}</h1>
			<p className='f-banner-intern-title-text f-p'>{description}</p>
			<Spacer />
		</div>
	)
}

export default BannerTitle
