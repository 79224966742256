import React, { useState, useRef } from 'react'
import {
	FormControl,
	FormGroup,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@material-ui/core'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import './userLocationInfoFormARG.scss'
import Radio from '../../../shared/components/atoms/radio/radio'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import InputMaterialWithCheck from "../../../shared/components/atoms/input-material-with-check/input-material-with-check"
import { NO_SPECIAL_CHARACTERS_PATTERN, ADDRESS_PATTERN, NUMBER_PATTERN, 
	FLOOR_PATTERN, DEPARTMENT_PATTERN, ADITIONAL_INFO_PATTERN} from '../../../shared/state/constants/appConstants'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const UserLocationInfoFormARG = ({
	userFormInfo,
	errors,
	register,
	handleChange,
	handleSelection,
	values,
	formValues,
	formState,
	citiesArray,
	isMyAccount,
	addLocationForm,
	selectLocation,
	selectedLocation,
	secondLocationForm,
	location,
	locationInfo,
	countryPosition,
	clearError
}) => {

	const { pais, ciudad, departamento } = userFormInfo
	const isSecondForm =
		secondLocationForm && location === 'secondaryLocation'
	const inputLabel = useRef(null)
	const userInfo = dataLayerUserInfo()
	const haveSecondAddress = values.secondAddress !== ' '

  const [checkFloor, setCheckFloor] = useState(false)
  const [checkDepartment, setCheckDepartment] = useState(false)
  const [secondCheckFloor, setSecondCheckFloor] = useState(false)
  const [secondCheckDepartment, setSecondCheckDepartment] = useState(false)

	const dataLaterDatosP = lab => {
		let loc = ''
		if (typeof window !== 'undefined') {
			loc = window.location.href
		}
		pushDataLayerEvent({
			event: 'Interacciones - Beneficios',
			category: 'beneficios',
			action: 'click',
			label: lab,
			location: loc,
			user_data: userInfo.user_data ? userInfo.user_data : '',
			log_status: userInfo.log_status,
		})
	}
	const valueAncientAddress = () => {
		const fullAddress = !isSecondForm
			? `${values.address} ${values.mainAddressDetail}`.toString()
			: `${values.secondAddress} ${values.secondAddressDetail}`
		return fullAddress
	}

	return (
		<>
			{!values.isNewAddress ? (
				<>
					<InputMaterial
						change={handleChange}
						disabled={true}
						errors={errors}
						type='text'
						autoComplete={false}
						classes='f-form-input f-form-input-ancientAddress'
						id={'f-user-neighborhood'}
						label={'Dirección Antigua'}
						value={valueAncientAddress().replaceAll('?', ' ')}
					/>
					<p className='f-section-subtitle'>Por favor actualiza tu dirección</p>
				</>
			) : (
				isSecondForm &&
				!values.isSecondNewAddress &&
				!haveSecondAddress && (
					<>
						<InputMaterial
							change={handleChange}
							disabled={true}
							errors={errors}
							type='text'
							autoComplete={false}
							classes='f-form-input f-form-input-ancientAddress'
							id={'f-user-neighborhood'}
							label={'Dirección Antigua'}
							value={valueAncientAddress().replaceAll('?', ' ')}
						/>
						<p className='f-section-subtitle'>
							Por favor actualiza tu dirección
						</p>
					</>
				)
			)}
			<FormGroup className='f-user-form'>
				<FormControl
					variant='outlined'
					className='f-select-input f-select-input-sample'
					disabled={true}
				>
					<InputLabel
						filled={true}
						ref={inputLabel}
						htmlFor='outlined-country-simple'
					>
						{pais}
					</InputLabel>
					<Select
						value={values.country || ''}
						onChange={handleSelection()}
						input={
							<OutlinedInput
								name={isSecondForm ? 'secondCountry' : 'country'}
								id={
									isSecondForm
										? 'outlined-country-simple-sec'
										: 'outlined-country-simple'
								}
							/>
						}
					>
						{locationInfo.map((item, index) => (
							<MenuItem key={index} value={item.codigoDelPais}>
								{item.titulo}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl
					variant='outlined'
					className='f-select-input f-select-input-sample'
					disabled={
						formValues.country === '' &&
						!Object.prototype.hasOwnProperty.call(
							formState?.touched,
							'state'
						) &&
						!isMyAccount
					}
				>
					<InputLabel ref={inputLabel} htmlFor='outlined-state-simple'>
						{departamento}
					</InputLabel>
					<Select
						value={
							isSecondForm
								? values.secondState || ''
								: values.state || ''
						}
						required
						displayEmpty
						onChange={handleSelection()}
						input={
							<OutlinedInput
								name={isSecondForm ? 'secondState' : 'state'}
								id={
									isSecondForm
										? 'outlined-state-simple-sec'
										: 'outlined-state-simple'
								}
							/>
						}
					>
						{locationInfo[countryPosition].departamentos.map(
							state => (
								<MenuItem key={'state' + state.codigo} value={state}>
									{state.nombre}
								</MenuItem>
							)
						)}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>
				<FormControl
					variant='outlined'
					className='f-select-input f-select-input-sample'
					disabled={
						formValues.state === '' &&
						!Object.prototype.hasOwnProperty.call(
							formState?.touched,
							'city'
						) &&
						!isMyAccount
					}
				>
					<InputLabel ref={inputLabel} htmlFor='outlined-city-simple'>
						{ciudad}
					</InputLabel>
					<Select
						name={isSecondForm ? 'secondCity' : 'city'}
						value={
							isSecondForm ? values.secondCity : values.city || ''
						}
						required
						displayEmpty
						onChange={handleSelection()}
						input={
							<OutlinedInput
								name={isSecondForm ? 'secondCity' : 'city'}
								id={
									isSecondForm
										? 'outlined-city-simple-sec'
										: 'outlined-city-simple'
								}
							/>
						}
					>
						{(citiesArray || []).map(city => (
							<MenuItem key={'city' + city.codigo} value={city}>
								{city.nombre}
							</MenuItem>
						))}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>

				{!isSecondForm && (
					<div className='f-form-input-group'>
						<div className='f-form-group'>
							<InputMaterial
								errors={errors}
								name='addressARG'
								type='text'
								autoComplete={false}
								classes='f-input-material-address--direccion'
								label="Dirección *"
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									minLength: {
										value: 5,
										message: 'El campo debe tener mínimo 5 caracteres',
									},
									pattern: {
										value: ADDRESS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
							/>
							<InputMaterial
								name='height'
								autoComplete={false}
								classes='f-input-material-address--height'
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 6,
										message: 'El campo debe tener máximo 6 dígitos',
									},
									pattern: {
										value: NUMBER_PATTERN,
										message: 'Solo son válidos números',
									},
								})}
								label={'Altura *'}
								errors={errors}
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterialWithCheck
								name='floor'
								id='floor'
								text='piso'
								classes='f-input-material-address--floor'
								autoComplete={false}
								change={handleChange}
								register={register({
									required: {
										value: !checkFloor,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 12,
										message: 'El campo debe tener máximo 12 caracteres',
									},
									pattern: {
										value: FLOOR_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Piso *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setCheckFloor}
								whatFormIs="actualizacion"
								formValue={formValues.floor}
							/>
							<InputMaterialWithCheck
								name='department'
								classes='f-input-material-address--department'
								id='department'
								text='departamento'
								autoComplete={false}
								change={handleChange}
								register={register({
									required: {
										value: !checkDepartment,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value:
											formValues.department === 'Sin departamento' ? 16 : 5,
										message: 'El campo debe tener máximo 5 caracteres',
									},
									pattern: {
										value: DEPARTMENT_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Departamento *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setCheckDepartment}
								whatFormIs="actualizacion"
								formValue={formValues.department}
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterial
								name='zipCode'
								autoComplete={false}
								change={handleChange}
								classes='f-input-material-address--zip-code'
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 8,
										message: 'El campo debe tener máximo 8 caracteres',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Código postal *'}
								errors={errors}
							/>
							<InputMaterial
								name='aditionalInformation'
								autoComplete={false}
								change={handleChange}
								classes='f-input-material-address--aditional-info'
								register={register({
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									pattern: {
										value: ADITIONAL_INFO_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Información adicional'}
								errors={errors}
							/>
						</div>
					</div>
				)}

				{isSecondForm && (
					<div className='f-form-input-group'>
						<div className='f-form-group'>
							<InputMaterial
								errors={errors}
								name='secondAddressARG'
								type='text'
								autoComplete={false}
								change={handleChange}
								classes='f-input-material-address--direccion'
								label="Dirección *"
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									minLength: {
										value: 5,
										message: 'El campo debe tener mínimo 5 caracteres',
									},
									pattern: {
										value: ADDRESS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
							/>
							<InputMaterial
								name='secondHeight'
								autoComplete={false}
								change={handleChange}
								classes='f-input-material-address--height'
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 6,
										message: 'El campo debe tener máximo 6 dígitos',
									},
									pattern: {
										value: NUMBER_PATTERN,
										message: 'Solo son válidos números',
									},
								})}
								label={'Altura *'}
								errors={errors}
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterialWithCheck
								name='secondFloor'
								id='secondFloor'
								text='piso'
								classes='f-input-material-address--floor'
								autoComplete={false}
								change={handleChange}
								register={register({
									required: {
										value: !secondCheckFloor,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 12,
										message: 'El campo debe tener máximo 12 caracteres',
									},
									pattern: {
										value: FLOOR_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Piso *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setSecondCheckFloor}
								whatFormIs="actualizacion"
								formValue={formValues.secondFloor}
							/>
							<InputMaterialWithCheck
								name='secondDepartment'
								classes='f-input-material-address--department'
								id='secondDepartment'
								text='departamento'
								autoComplete={false}
								change={handleChange}
								register={register({
									required: {
										value: !secondCheckDepartment,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value:
											formValues.secondDepartment === 'Sin departamento' ? 16 : 5,
										message: 'El campo debe tener máximo 5 caracteres',
									},
									pattern: {
										value: DEPARTMENT_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Departamento *'}
								errors={errors}
								clearError={clearError}
								setCheckRequired={setSecondCheckDepartment}
								formValue={formValues.secondDepartment}
								whatFormIs="actualizacion"
							/>
						</div>
						<div className='f-form-group'>
							<InputMaterial
								name='secondZipCode'
								autoComplete={false}
								classes='f-input-material-address--zip-code'
								change={handleChange}
								register={register({
									required: {
										value: true,
										message: 'Este campo es requerido',
									},
									maxLength: {
										value: 8,
										message: 'El campo debe tener máximo 8 caracteres',
									},
									pattern: {
										value: NO_SPECIAL_CHARACTERS_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Código postal *'}
								errors={errors}
							/>
							<InputMaterial
								name='secondAditionalInformation'
								autoComplete={false}
								classes='f-input-material-address--aditional-info'
								change={handleChange}
								register={register({
									maxLength: {
										value: 30,
										message: 'El campo debe tener máximo 30 caracteres',
									},
									pattern: {
										value: ADITIONAL_INFO_PATTERN,
										message: 'El campo contiene caracteres inválidos',
									},
								})}
								label={'Información adicional'}
								errors={errors}
							/>
						</div>
					</div>
				)}
				{isMyAccount &&
					(secondLocationForm ? (
						<div className='f-btn-add-wrapper'>
							<div className={'f-btn-radio'} onClick={addLocationForm}>
								<Radio
									isActive={location === selectedLocation}
									setActive={() => {
										location === 'secondaryLocation'
											? selectLocation('secondaryLocation')
											: selectLocation('mainLocation')
									}}
								/>
							</div>
							<p>Establecer como dirección de envio</p>
						</div>
					) : (
						<>
							<div className='f-btn-add-wrapper'>
								<div
									className={'f-btn-add'}
									onClick={() => {
										addLocationForm()
										dataLaterDatosP('Agregar otra dirección')
									}}
								>
									<p>{convertText('AGREGAR OTRA DIRECCIÓN')}</p>
								</div>
							</div>
						</>
					))}
			</FormGroup>
		</>
	)
}

export default UserLocationInfoFormARG
