import { graphql } from 'gatsby'
import React, { useState } from 'react'
import BannerTitle from '../../components/atoms/banner-title/banner-title'
import AccountScoreHeader from '../../components/molecules/accountScoreHeader/accountScoreHeader'
import AlertMessagge from '../../components/molecules/alertMessagge/alertMessagge'
import InstitutionAccountContainer from '../../components/organisms/institutionAccountContainer/institutionAccountContainer'
import UserAccountContainer from '../../components/organisms/userAccountContainer/userAccountContainer'
import iconCheck from '../../shared/assets/images/icon-check.svg'
import Button from '../../shared/components/atoms/Button/Button'
import SearchAdviser from '../../shared/components/atoms/search-adviser/search-adviser'
import ModalOTP from '../../shared/components/molecules/modalOTP/modalOTP'
import TabProducts from '../../shared/components/molecules/tabProducts/tabProducts'
import Layout from '../../shared/components/organisms/layout/layout'
import LayoutModal from '../../shared/components/organisms/layoutModal/layout-modal'
import useBrowserMode from '../../shared/hooks/useBrowserMode'
import useLocaleMode from '../../shared/hooks/useLocaleMode'
import useMobileMode from '../../shared/hooks/useMobileMode'
import useSessionState from '../../shared/hooks/useSessionState'
import SessionService from '../../shared/services/session'
import { ROL_INSTITUCION } from '../../shared/state/constants/appConstants'
import { localStorage, sessionStorage } from '../../shared/state/utils/storage'
import usePointsMode from '../../shared/hooks/usePointsMode'
import { pushDataLayerEvent } from '../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../shared/utils/userInfo'
import '../../components/atoms/pass-cta-add/pass-cta-add.scss'
import { convertText } from '../../shared/utils/convertFirstCharacterToUppercase'

const DatosPersonales = props => {
	const { isMobile, currentPage } = useMobileMode()
	const { actualStorage } = useSessionState()
	const { isBrowser } = useBrowserMode()
	const { locale } = useLocaleMode()
	const userData = isBrowser && SessionService.getUserData()
	const { currentUser, setCurrentUser } = usePointsMode()
	const [passwordModal, setPasswordModal] = useState(false)

	const [storageInfo, setStorageInfo] = useState(
		isBrowser && actualStorage === 0
			? localStorage.getItem('userData')
			: sessionStorage.getItem('userData')
	)
	const [isInstitution] = useState(
		storageInfo &&
			storageInfo.roles.filter(
				role => role.nombreRol === ROL_INSTITUCION.nombreRol
			).length === 1
	)

	const [currentPayload, setCurrentPayload] = React.useState()
	const [isShowingOTPModal, setOTPModalVisibility] = React.useState(false)
	const [hasFormChanged, setFormChange] = React.useState(false)
	const [isFormSubmitted, setFormSubmit] = React.useState(false)

	const {
		idSoyUsuario,
		idSoyProfesional,
		idSoyCuidador,
		idSoyComprador,
		idInstitucion,
		soyCuidadorTitulo,
		soyCuidadorDescripcion,
		soyCuidadorImagen,
		soyCompradorDescripcion,
		soyCompradorTitulo,
		soyCompradorImagen,
		soyProfesionalDescripcion,
		soyProfesionalTitulo,
		noSoyProfesionalTitulo,
		noSoyProfesionalDescripcion,
		soyUsuarioDescripcion,
		soyUsuarioTitulo,
		soyUsuarioImagen,
	} = props.data.allContentfulTenaRegistro.nodes[0]

	const registerRolesInfo = {
		idSoyUsuario,
		idSoyProfesional,
		idSoyCuidador,
		idSoyComprador,
		idInstitucion,
		soyCuidadorTitulo,
		soyCuidadorDescripcion,
		soyCuidadorImagen,
		soyCompradorDescripcion,
		soyCompradorTitulo,
		soyCompradorImagen,
		soyProfesionalDescripcion,
		soyProfesionalTitulo,
		soyUsuarioDescripcion,
		soyUsuarioTitulo,
		soyUsuarioImagen,
		noSoyProfesionalTitulo,
		noSoyProfesionalDescripcion,
	}
	const userInfo=dataLayerUserInfo()
	const dataLaterDatosP = (lab) => {		
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Mi Cuenta',
		category: 'datos personales',
		action: 'click',
		label:lab,
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}

	return (
		<>
			{isBrowser && (
				<Layout
					currentUser={currentUser}
					isMobile={isMobile}
					currentPage={currentPage}
				>
					<article>
						<>
							<SearchAdviser />
						</>
						<AccountScoreHeader
							actualScore={userData.points}
							pointsToExpire={userData.pointsToExpire}
							datePointsToExpire={userData.datePointsToExpire}
							locale={locale}
						/>
						<TabProducts
							tabs={props.data.allContentfulTenaMenu.nodes[0]}
							slug={'mi-cuenta/datos-personales'}
						/>
						<div className='f-top-wrapper'>
							<BannerTitle
								title={
									isInstitution ? 'DATOS DE LA INSTITUCIÓN' : 'DATOS PERSONALES'
								}
								description={
									isInstitution
										? 'Aquí podrás actualizar los datos de tu institución, su información general y la de sus sedes'
										: 'Aquí podrás actualizar tus datos, tu perfil y direcciones de envío'
								}
								paddingLeft={'5%'}
							/>
							<p
								className='f-password-cta'
								onClick={() => {setPasswordModal(true); dataLaterDatosP('Cambiar mi contraseña')}}
							>
								{convertText('CAMBIAR MI CONTRASEÑA')}
							</p>
						</div>
						{isInstitution ? (
							<InstitutionAccountContainer
								setCurrentUser={setCurrentUser}
								userFormInfo={props.data.allContentfulTenaRegistro.nodes[0]}
								storageInfo={storageInfo}
								actualStorage={actualStorage}
								locationInfo={props.data.allContentfulTenaPaises.nodes}
								setCurrentPayload={setCurrentPayload}
								setFormChange={setFormChange}
								setFormSubmit={setFormSubmit}
								hasFormChanged={hasFormChanged}
								passwordModal={passwordModal}
								setPasswordModal={setPasswordModal}
								setStorageInfo={setStorageInfo}
							/>
						) : (
							<UserAccountContainer
								setCurrentUser={setCurrentUser}
								userFormInfo={props.data.allContentfulTenaRegistro.nodes[0]}
								registerRolesInfo={registerRolesInfo}
								storageInfo={storageInfo}
								actualStorage={actualStorage}
								locationInfo={props.data.allContentfulTenaPaises.nodes}
								setCurrentPayload={setCurrentPayload}
								setFormChange={setFormChange}
								setFormSubmit={setFormSubmit}
								hasFormChanged={hasFormChanged}
								passwordModal={passwordModal}
								setPasswordModal={setPasswordModal}
								setStorageInfo={setStorageInfo}
							/>
						)}
						{isFormSubmitted && (
							<LayoutModal>
								<AlertMessagge
									icon={<img src={iconCheck} alt='check-correct' />}
									messagge='LOS DATOS INGRESADOS HAN SIDO ACTUALIZADOS CON ÉXITO'
									type='success'
									button={
										<Button
											type='button'
											onClickAction={() => {
												setFormSubmit(false)
												setFormChange(false)
											}}
											classname='f-button f-button--primary'
											text={convertText('ACEPTAR')}
										/>
									}
								/>
							</LayoutModal>
						)}
						{isShowingOTPModal && (
							<ModalOTP
								isAccount={true}
								payload={currentPayload}
								setCurrentUser={setCurrentUser}
								type='userInfo'
								actualStorage={actualStorage}
								onClose={() => {
									setOTPModalVisibility(false)
								}}
								setFormSubmit={setFormSubmit}
							/>
						)}
					</article>
				</Layout>
			)}
		</>
	)
}

export const query = graphql`
	query MY_ACCOUNT_DATA($language: String!) {
		allContentfulTenaMenu(
			filter: {
				node_locale: { eq: $language }
				titulo: { eq: "Menú > Mi Cuenta" }
			}
		) {
			nodes {
				opcionesMenu {
					nombre
					slug
					categoria {
						slug
					}
				}
			}
		}
		allContentfulTenaRegistro(
			filter: {
				node_locale: { eq: $language }
				titulo: { eq: "Registro > Paso Tres" }
			}
		) {
			nodes {
				formularioInformacionPersonal {
					apellido
					pais
					departamento
					ciudad
					direccion
					fechaNacimiento
					genero
					lblApellido
					lblDireccion
					lblNombre
					lblNumeroTelefonico
					nombre
					numeroTelefonico
				}
				formularioInstitucion {
					apellidosResponsable
					pais
					departamento
					ciudad
					correoElectronico
					correoElectronicoResponsable
					direccion
					enfoqueInstitucion
					lblApellidosResponsable
					lblCorreoElectronico
					lblCorreoElectronicoResponsable
					lblDireccion
					lblNit
					lblNumeroCuidadores
					lblNombreInstitucion
					lblNombreResponsable
					lblNumeroTelefonicoInstitucion
					lblNumeroTelefonicoResponsable
					lblNumeroUsuarios
					lblNumeroUsuariosIncontinencia
					nit
					nombreInstitucion
					nombreResponsable
					numeroCuidadores
					numeroTelefonicoInstitucion
					numeroTelefonicoResponsable
					numeroUsuarios
					numerosUsuariosIncontinencia
					enfoque {
						nombre
						descripcion
						valor
					}
					cargos
					listaDeCargos
					pais
					seccionTresFormulario
					seccionUnoFormulario
					seccionDosFormulario
					subtituloPrincipal
					tituloPrincipal
				}
				idSoyUsuario
				idSoyProfesional
				idSoyCuidador
				idSoyComprador
				idInstitucion
				soyCuidadorTitulo
				soyCuidadorDescripcion
				soyCuidadorImagen {
					description
					file {
						url
					}
				}
				soyCompradorDescripcion
				soyCompradorTitulo
				soyCompradorImagen {
					description
					file {
						url
					}
				}
				soyProfesionalDescripcion
				soyProfesionalTitulo
				noSoyProfesionalTitulo
				noSoyProfesionalDescripcion
				soyUsuarioDescripcion
				soyUsuarioTitulo
				soyUsuarioImagen {
					description
					file {
						url
					}
				}
			}
		}
		allContentfulTenaPaises(
			sort: { fields: orden, order: ASC }
			filter: { node_locale: { eq: $language } }
		) {
			nodes {
				codigoDeDosLetras
				codigoDelPais
				lenguaje
				titulo
				dominio
				departamentos {
					nombre
					codigo
					id
				}
				ciudades {
					codigo
					codigoDepartamento
					id
					nombre
				}
				prefijoTelefonico
				tipoDeCalle{
					idCalle
					name
				}
			}
		}
	}
`

export default DatosPersonales
