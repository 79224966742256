import React from 'react'
import {
	FormGroup,
	Select,
	OutlinedInput,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import {
	NAME_PATTERN,
	NAME_MIN_LENGTH,
} from '../../../shared/state/constants/appConstants'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import './institutionAdminInfoForm.scss'

const InstitutionAdminInfoForm = props => {
	const {
		nombreResponsable,
		apellidosResponsable,
		cargos,
		listaDeCargos,
	} = props.institutionFormInfo

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])
	return (
		<FormGroup
			className={`f-form-section f-form-section-secondary ${props.isMyAccount &&
				'f-form-section-noborder'}`}
		>
			<InputMaterial
				change={props.handleChange}
				disabled={
					props.formValues.adminName === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState.touched,
						'adminName'
					) &&
					!props.isMyAccount
				}
				errors={props.errors}
				name='adminName'
				type='text'
				autoComplete={false}
				classes='f-form-input'
				id='f-institution-helper-adminName'
				label={nombreResponsable}
				register={props.register({
					required: { value: true, message: 'El nombre es requerido.' },
					pattern: {
						value: NAME_PATTERN,
						message: 'El nombre ingresado es inválido.',
					},
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'El nombre debe tener mínimo 3 caracteres.',
					},
				})}
			/>
			<InputMaterial
				change={props.handleChange}
				disabled={
					props.formValues.adminLastName === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState.touched,
						'adminLastName'
					) &&
					!props.isMyAccount
				}
				errors={props.errors}
				name='adminLastName'
				type='text'
				autoComplete={false}
				classes='f-form-input'
				id='f-institution-helper-adminLastName'
				label={apellidosResponsable}
				register={props.register({
					required: { value: true, message: 'Los apellidos son requeridos.' },
					pattern: {
						value: NAME_PATTERN,
						message: 'Los apellidos ingresados son inválidos.',
					},
					minLength: {
						value: NAME_MIN_LENGTH,
						message: 'Los apellidos deben tener mínimo 3 caracteres.',
					},
				})}
			/>

			<FormControl
				disabled={
					props.formValues.adminGender === '' &&
					Object.prototype.hasOwnProperty.call(
						props.formState.touched,
						'adminGender'
					) &&
					!props.isMyAccount
				}
				variant='outlined'
				className='f-form-input f-form-input-select'
				error={props.errors.adminGender != null}
			>
				<InputLabel ref={inputLabel} htmlFor='outlined-adminGender-simple'>
					{'¿Cuál es tu género?'}
				</InputLabel>
				<Select
					name='adminGender'
					onChange={props.handleSelection()}
					displayEmpty
					defaultValue={props.formValues.adminGender}
					input={
						<OutlinedInput
							name='adminGender'
							labelWidth={labelWidth}
							id='outlined-adminGender-simple'
						/>
					}
				>
					<MenuItem value='Femenino'>Femenino</MenuItem>
					<MenuItem value='Masculino'>Masculino</MenuItem>
				</Select>
				<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
			</FormControl>

			<FormControl
				disabled={
					props.formValues.adminPosition === '' &&
					!Object.prototype.hasOwnProperty.call(
						props.formState.touched,
						'position'
					) &&
					!props.isMyAccount
				}
				variant='outlined'
				className='f-form-input f-form-input-select'
			>
				<InputLabel htmlFor='f-institute-position'>{cargos}</InputLabel>
				<Select
					name='adminPosition'
					displayEmpty
					defaultValue={props.formValues.adminPosition}
					onChange={props.handleSelection()}
					input={
						<OutlinedInput name='adminPosition' id='f-institute-position' />
					}
				>
					{listaDeCargos.map((position, index) => {
						return (
							<MenuItem key={index} value={position}>
								{position}
							</MenuItem>
						)
					})}
				</Select>
				<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
			</FormControl>
		</FormGroup>
	)
}

export default InstitutionAdminInfoForm
